import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { useLanguagesStore } from "../../stores/languages";
import * as APIMock from "@/assets/API/APIMock";
import * as APItypes from "@/assets/API/APItype";
const isDevelopment = import.meta.env.VITE_CONFIG_MODE === "";
const windowBaseURL = () => {
  const mode = import.meta.env.MODE;
  let url;
  switch (mode) {
    case "TTB":
      url = "https://adm06.ttb999bet.com/api/client_use/";
      break;
    case "MT":
      // url = "https://admmtbet.mtbet168.com/api/client_use/";
      url = "https://ags.mtag88bet.com/api/client_use/";
      break;
    case "MM":
      url = "https://adm.icv8888.net/api/client_use/";
      break;
    // case "white03test":
    //   url = "https://admw3.icv8888.net/api/client_use/";
    //   break;
    case "JPStest":
      url = "https://myadm.dtap000s3.com/api/client_use/";
      break;
    case "JPS":
      url = "https://myadm.dtap000s3.com/api/client_use/";
      break;
    case "ICVCN":
    case "ICVCNtest":
      url = "https://admicvcn.icv8888.net/";
      break;
    case "white03test":
    case "MTtest":
    case "MMtest":
    case "TTBtest":
    case "ICVtest":
    case "ICV":
    case "BLUEtest":
    case "BLUE":
    case "CYFtest":
    case "SINHAOtest":
    case "SHtest":
      url = "https://adm.icv8888.net/api/client_use/";
      break;
    case "ULtest":
      url = "https://adm.icv8888.net/api/client_use/";
      break;
    case "CYF":
      url = "https://admcyf888.cyf888.com/api/client_use/";
      break;
    case "SH":
      url = "https://admsn16.icv8888.net/api/client_use/";
      break;
    case "UL":
      url = "https://agsul.ul1688.com/api/client_use/";
      break;
    case "N12":
      url = "https://admn12.icv8888.net/api/client_use/";
      break;
    case "sinhao":
      url = "https://apixh.xh8888.net/api/client_use/";
      break;
    default:
      url = "";
      break;
  }
  switch (window.location.hostname) {
    case "n01.icv8888.net":
      url = "https://admn01.icv8888.net/api/client_use/";
      break;
    case "n02.icv8888.net":
      url = "https://admn02.icv8888.net/api/client_use/";
      break;
    case "n03.icv8888.net":
      url = "https://admn03.icv8888.net/api/client_use/";
      break;
    case "n05.icv8888.net":
      url = "https://admn05.icv8888.net/api/client_use/";
      break;
    case "n06.icv8888.net":
      url = "https://admn06.icv8888.net/api/client_use/";
      break;
    case "n07.icv8888.net":
      url = "https://admn07.icv8888.net/api/client_use/";
      break;
    case "n08.icv8888.net":
      url = "https://admn08.icv8888.net/api/client_use/";
      break;
    case "n09.icv8888.net":
      url = "https://admn09.icv8888.net/api/client_use/";
      break;
    case "n10.icv8888.net":
      url = "https://admn10.icv8888.net/api/client_use/";
      break;
    case "n11.icv8888.net":
      url = "https://admn11.icv8888.net/api/client_use/";
      break;
    default:
      break;
  }
  return url;
};
const CookieJump = () => {
  let JumpCookie = "";

  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // 去除首尾空格
    const parts = cookie.split("=");
    const name = parts[0];
    const value = parts[1];

    // 找到了名为 "jump" 的 Cookie
    if (name === "jump") {
      JumpCookie = value;
      break;
    }
  }
  return JumpCookie;
};

export const serverRequest = axios.create({
  baseURL: windowBaseURL(),
  // https://adm.icv8888.net/api/client_use/
  // https://admn01.icv8888.net/api/client_use/
  // https://newadm.icv8888.net/api/client_use/
});
const sLang = () => {
  let type;
  switch (localStorage.getItem("Language")) {
    case "zh-TW":
      type = "tw";
      break;
    case "zh-CH":
      type = "cn";
      break;
    case "my-MM":
      type = "vn";
      break;
    case "en-US":
      type = "en";
      break;
    case "vi-VN":
      type = "vn";
      break;
    case "ja-JP":
      type = "jp";
      break;
    default:
      type = "tw";
      break;
  }
  return type;
};
const jump = CookieJump();
serverRequest.defaults.params = {
  sLang: sLang(),
  jump,
};

export const mock = false
  ? new MockAdapter(serverRequest, { delayResponse: 2000 })
  : null;

// 首頁資料API
if (mock) {
  mock.onPost(`index/setArray.php`).reply(200, APIMock.setArrayRes);
}
export const postIndexInfo = async (data: APItypes.IndexInfoData) => {
  return serverRequest
    .post(`index/setArray.php`, data)
    .then((res) => {
      // console.log(res, "postIndexInfo");
      return res.data || ({} as APItypes.IndexInfoResponse);
    })
    .catch(() => {
      return {} as APItypes.IndexInfoResponse;
    });
};

// 發送m_user_c cooclkie
if (mock) {
  mock.onGet(`member/m_user_c.php`).reply(200, APIMock.MUserCRes);
}
export const getMUserC = async () => {
  return await serverRequest
    .get(`member/m_user_c.php`)
    .then((res) => {
      // console.log(res, "getMUserC");
      return res.data as APItypes.MUserCResponse;
    })
    .catch(() => {
      return {
        m_user_c_cookie: "",
      } as APItypes.MUserCResponse;
    });
};

// 拿跑馬燈&輪播圖秒數 API
if (mock) {
  mock.onPost(`index/get_aSeconds.php`).reply(200, APIMock.setArrayRes);
}
export const postaSeconds = async (data: APItypes.AuthData) => {
  return serverRequest
    .post(`index/get_aSeconds.php`, data)
    .then((res) => {
      // console.log(res, "postaSeconds");
      return res.data || ({} as APItypes.aSecondsResponse);
    })
    .catch(() => {
      return {} as APItypes.aSecondsResponse;
    });
};

// 拿在線人數
if (mock) {
  mock.onPost(`index/user_sum.php`).reply(200, APIMock.userSum);
}
export const postuserSum = async ({ m_user_c }: APItypes.postuserSum) => {
  return serverRequest
    .get(`index/user_sum.php?m_user_c=${m_user_c}`)
    .then((res) => {
      // console.log(res, "postuserSum");
      return res.data || ({} as APItypes.IndexInfoResponse);
    })
    .catch(() => {
      return {} as APItypes.IndexInfoResponse;
    });
};

// 判斷是否開啟手機驗證
if (mock) {
  mock
    .onPost(`phone_vscode/phone_switch.php`)
    .reply(200, APIMock.MemberRegisterRes);
}
export const postPhoneSwitch = async (data: APItypes.PhoneSwitchData) => {
  return serverRequest
    .post(`phone_vscode/phone_switch.php`, data)
    .then((res) => {
      // console.log(data, res, "phone_switch");
      return res.data || ({} as APItypes.IndexInfoResponse);
    })
    .catch(() => {
      return {} as APItypes.IndexInfoResponse;
    });
};

// 遊戲
if (mock) {
  mock.onPost(`lobby/get_lobby_list.php`).reply(200, APIMock.LobbyListRes);
}
export const postLobbyList = async (data: APItypes.LobbyListData) => {
  return serverRequest
    .post(`lobby/get_lobby_list.php`, data)
    .then((res) => {
      // console.log(res, "lobby_list");
      return res.data || ({} as APItypes.LobbyListResponse);
    })
    .catch(() => {
      return {} as APItypes.LobbyListResponse;
    });
};

export const postLotteryGameLoginCheck = async (
  data: APItypes.LotteryGameLoginCheckData
) => {
  return serverRequest
    .post(`lottery/_api_lottery_game_login_check.php`, data)
    .then((res) => {
      // console.log(res, "lobby_list");
      return res.data || ({} as APItypes.LotteryGameLoginCheckResponse);
    })
    .catch(() => {
      return {} as APItypes.LotteryGameLoginCheckResponse;
    });
};

// 拿取手機簡訊驗證碼
if (mock) {
  mock
    .onPost(`phone_vscode/send_msg.php`)
    .reply(200, APIMock.MemberRegisterRes);
}
export const postSendMsg = async (data: APItypes.PhoneSendMsg) => {
  return serverRequest
    .post(`phone_vscode/send_msg.php`, data)
    .then((res) => {
      // console.log(data, res, "PhoneSendMsg");
      return res.data || ({} as APItypes.IndexInfoResponse);
    })
    .catch(() => {
      return {} as APItypes.IndexInfoResponse;
    });
};

// 檢查推廣碼
// if (mock) {
//   mock
//     .onPost(`proxy/promotion_chk.php`)
//     .reply(200, APIMock.MemberRegisterRes);
// }
export const postPromotionChk = async (data: APItypes.PromotionChkData) => {
  return serverRequest
    .post(`proxy/promotion_chk.php`, data)
    .then((res) => {
      // console.log(data, res, "postPromotionChk");
      return res.data as APItypes.PromotionChkResponse;
    })
    .catch(() => {
      return {
        type: 3,
        msg: "接收資料異常",
      } as APItypes.PromotionChkResponse;
    });
};

// 會員註冊(註冊成功後會登入)
if (mock) {
  mock
    .onPost(`member/member_register.php`)
    .reply(200, APIMock.MemberRegisterRes);
}
export const postMemberRegister = async (data: APItypes.MemberRegisterData) => {
  return serverRequest
    .post(`member/member_register.php`, data)
    .then((res) => {
      // console.log(data, res, "postMemberRegister");
      return res.data as APItypes.MemberRegisterResponse;
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      } as APItypes.MemberRegisterResponse;
    });
};

// 會員手機註冊(註冊成功後會登入)
if (mock) {
  mock
    .onPost(`member/member_phone_register.php`)
    .reply(200, APIMock.MemberRegisterRes);
}
export const postMemberPhoneRegister = async (
  data: APItypes.MemberPhoneRegisterData
) => {
  return serverRequest
    .post(`member/member_phone_register.php`, data)
    .then((res) => {
      // console.log(data, res, "postMemberPhoneRegister");
      return res.data as APItypes.MemberRegisterResponse;
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      } as APItypes.MemberRegisterResponse;
    });
};

// 會員手機註冊(註冊成功後會登入)
if (mock) {
  mock
    .onPost(`member/share_register.php`)
    .reply(200, APIMock.MemberRegisterRes);
}
export const postShareRegister = async (data: APItypes.ShareRegisterData) => {
  return serverRequest
    .post(`member/share_register.php`, data)
    .then((res) => {
      // console.log(data, res, "postShareRegister");
      return res.data as APItypes.MemberRegisterResponse;
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      } as APItypes.MemberRegisterResponse;
    });
};

// 分享發財頁面
if (mock) {
  mock.onPost(`share/share_page.php`).reply(200, APIMock.SharePageRes);
}
export const postSharePage = async (data: APItypes.SharePageData) => {
  return serverRequest
    .post(`share/share_page.php`, data)
    .then((res) => {
      // console.log(data, res, "postSharePage");
      return res.data as APItypes.SharePageResponse;
    })
    .catch(() => {
      // console.log(data, "postSharePage catch");
      return {
        type: 99,
        aShareData: {
          sUrl: "",
          sQrImg: "",
        },
        sMsg: "接收資訊異常",
      } as APItypes.SharePageResponse;
    });
};

// 分享發財下線列表
if (mock) {
  mock.onPost(`share/share_list.php`).reply(200, APIMock.ShareListRes);
}
export const postShareList = async (data: APItypes.ShareListData) => {
  return serverRequest
    .post(`share/share_list.php`, data)
    .then((res) => {
      // console.log(data, res, "postShareList");
      return res.data as APItypes.ShareListResponse;
    })
    .catch(() => {
      // console.log(data, "postShareList catch");
      return {
        type: 99,
        aShareList: {} as APItypes.ShareListAShareList,
        nCount: 0,
        sMsg: "接收資訊異常",
      } as APItypes.ShareListResponse;
    });
};

// 分享發財回饋紀錄
if (mock) {
  mock.onPost(`share/share_fs_log.php`).reply(200, APIMock.ShareFsLogRes);
}
export const postShareFsLog = async (data: APItypes.ShareFsLogData) => {
  return serverRequest
    .post(`share/share_fs_log.php`, data)
    .then((res) => {
      // console.log(data, res, "postShareFsLog");
      return res.data as APItypes.ShareFsLogResponse;
    })
    .catch(() => {
      // console.log(data, "postShareFsLog catch");
      return {
        type: 99,
        aShareFsLog: {} as APItypes.ShareFsLogAShareFsLogList,
        sMsg: "接收資訊異常",
        nCount: 0,
      } as APItypes.ShareFsLogResponse;
    });
};

//忘記密碼
if (mock) {
  mock.onPost(`phone_vscode/sms_send_new_password.php`).reply(200, APIMock.MemberLoginRes);
}
export const postForget = async (data: APItypes.MemberForgetData) => {
  return serverRequest
    .post(`phone_vscode/sms_send_new_password.php`, data)
    .then((res) => {
      console.log(data, res, "postAuth");
      return res.data as APItypes.MemberForgetResponse;
    })
    .catch(() => {
      console.log(data, "postAuth catch");
      return {
        type: 99,
        msg: "接收資訊異常",
      } as APItypes.MemberForgetResponse;
    });
};

// 會員登入
if (mock) {
  mock.onPost(`member/login.php`).reply(200, APIMock.MemberLoginRes);
}
export const postLogin = async (data: APItypes.MemberLoginData) => {
  return serverRequest
    .post(`member/login.php`, data)
    .then((res) => {
      // console.log(data, res, "postAuth");
      return res.data as APItypes.MemberLoginResponse;
    })
    .catch(() => {
      // console.log(data, "postAuth catch");
      return {
        type: 99,
        msg: "接收資訊異常",
      } as APItypes.MemberLoginResponse;
    });
};

// 拿登入驗證碼
if (mock) {
  mock.onPost(`member/vscode.php`).reply(200, APIMock.vscodeRes);
}
export const postVscode = async (data: APItypes.VscodeData) => {
  return serverRequest
    .post(`member/vscode.php`, data)
    .then((res) => {
      // console.log(data, res, "postVscode");
      return res.data as APItypes.VscodeResponse;
    })
    .catch(() => {
      // console.log(data, "postAuth catch");
      return {
        type: "3",
        msg: "接收資訊異常",
        dataList: "error",
      } as APItypes.VscodeResponse;
    });
};

// 會員驗證
if (mock) {
  mock.onPost(`member/auth.php`).reply(200, APIMock.AuthRes);
}
export const postAuth = async (data: APItypes.AuthData) => {
  return serverRequest
    .post(`member/auth.php`, data)
    .then((res) => {
      // console.log(data, res, "postAuth");
      return res.data as APItypes.AuthResponse;
    })
    .catch(() => {
      // console.log(data, "postAuth catch");
      return {
        type: 3,
        memberData: false,
      } as APItypes.AuthResponse;
    });
};

// 會員登出
export const postLogout = async (data: APItypes.MemberLogoutData) => {
  return serverRequest
    .post(`member/logout.php`, data)
    .then((res) => {
      // console.log(data, res, "postLogout");
      return res.data as APItypes.MemberLogoutResponse;
    })
    .catch(() => {
      // console.log(data, "postLogout catch");
      return {
        type: 3,
        msg: "資料接收失敗",
      } as APItypes.MemberLogoutResponse;
    });
};

// 會員金錢返回
if (mock) {
  mock.onPost(`member/money_back.php`).reply(200, APIMock.MoneyBackRes);
}
export const postMoneyBack = async (data: APItypes.MoneyBackData) => {
  return serverRequest
    .post(`member/money_back.php`, data)
    .then((res) => {
      // console.log(data, res, `postMoneyBack`);
      return res.data as APItypes.MoneyBackResponse;
    })
    .catch(() => {
      // console.log(data, "postMoneyBack catch");
      return {
        type: "3",
        msg: "傳送資料異常",
      } as APItypes.MoneyBackResponse;
    });
};

// 會員更換密碼&交易密碼
if (mock) {
  mock.onPost(`proxy/changepwd.php`).reply(200, APIMock.ChangepwdRes);
}
export const postChangepwd = async (data: APItypes.ChangepwdData) => {
  return serverRequest
    .post(`proxy/changepwd.php`, data)
    .then((res) => {
      // console.log(data, res, "postChangepwd");
      return res.data as APItypes.ChangepwdResponse;
    })
    .catch(() => {
      // console.log(data, "postChangepwd catch");
      return {
        type: "3",
        msg: "傳送資料異常",
      } as APItypes.ChangepwdResponse;
    });
};

// 讀取會員信箱
if (mock) {
  mock.onPost(`member/mail_count.php`).reply(200, APIMock.MailBoxCountRes);
}
export const postMailBoxCount = async (data: APItypes.MailBoxCountData) => {
  return serverRequest
    .post(`member/mail_count.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MailBoxCountResponse) ||
        ({} as APItypes.MailBoxCountResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [
          {
            count: "0",
          },
        ],
      } as APItypes.MailBoxCountResponse;
    });
};

// 讀取會員信箱
if (mock) {
  mock.onPost(`member/mail_box.php`).reply(200, APIMock.MailBoxRes);
}
export const postMailBox = async (data: APItypes.MailBoxData) => {
  return serverRequest
    .post(`member/mail_box.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MailBoxResponse) ||
        ({} as APItypes.MailBoxResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.MailBoxResponse;
    });
};

// 刪除會員已讀訊息
if (mock) {
  mock.onPost(`member/mail_delete.php`).reply(200, APIMock.MailBoxDeleteRes);
}
export const postMailDelete = async (data: APItypes.MailBoxDeleteData) => {
  return serverRequest
    .post(`member/mail_delete.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MailBoxDeleteResponse) ||
        ({} as APItypes.MailBoxDeleteResponse)
      );
    })
    .catch(() => {
      return {} as APItypes.MailBoxDeleteResponse;
    });
};

// 已讀訊息
if (mock) {
  mock.onPost(`member/mail_read.php`).reply(200, APIMock.MailBoxReadRes);
}
export const postMailRead = async (data: APItypes.MailBoxReadData) => {
  return serverRequest
    .post(`member/mail_read.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MailBoxReadResponse) ||
        ({} as APItypes.MailBoxReadResponse)
      );
    })
    .catch(() => {
      return {} as APItypes.MailBoxReadResponse;
    });
};

// 接收客服訊息
export const postWebTalk = async (data: APItypes.WebTalkReadData) => {
  return serverRequest
    .post(`talk/web_talk.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.WebTalkReadResponse) ||
        ({} as APItypes.WebTalkReadResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        dataList: {},
      };
    });
};

// 傳送客服訊息
// data: any = APItypes.WebTalkSendData
export const postWebTalkSend = async (data: any) => {
  return serverRequest
    .post(`talk/web_talk_send.php`, data)
    .then((res) => {
      // console.log(res, "postWebTalkSend");
      return (
        (res.data as APItypes.WebTalkSendResponse) ||
        ({} as APItypes.WebTalkSendResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        dataList: {},
        nTypeImg: 3,
        sMsgImg: "",
      };
    });
};

// 接收會員信箱, 電話
if (mock) {
  mock.onPost(`member/phone_mail.php`).reply(200, APIMock.phoneMailRes);
}
export const postPhoneMail = async (data: APItypes.PhoneMailData) => {
  return serverRequest
    .post(`member/phone_mail.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.PhoneMailResponse) ||
        ({} as APItypes.PhoneMailResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "PhoneMail資料接收異常",
        dataList: {},
      } as APItypes.PhoneMailResponse;
    });
};

// 修改會員信箱
export const postMailUpdate = async (data: APItypes.MailUpdateData) => {
  return serverRequest
    .post(`member/phone_mail_update.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MailUpdateResponse) ||
        ({} as APItypes.MailUpdateResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "資料接收異常",
        dataList: {},
      } as APItypes.MailUpdateResponse;
    });
};

// 修改會員Line
export const postLineUpdate = async (data: APItypes.LineUpdateData) => {
  return serverRequest
    .post(`member/line_update.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.LineUpdateResponse) ||
        ({} as APItypes.LineUpdateResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "資料接收異常",
        dataList: {},
      } as APItypes.LineUpdateResponse;
    });
};

// VIP等級列表
if (mock) {
  mock.onPost(`member/vip_list.php`).reply(200, APIMock.VipListRes);
}
export const postVipList = async (data: APItypes.VipListData) => {
  return serverRequest
    .post(`member/vip_list.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.VipListResponse) ||
        ({} as APItypes.VipListResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "資料接收異常",
        dataList: [],
      } as APItypes.VipListResponse;
    });
};
// 查詢交易紀錄
if (mock) {
  mock
    .onPost(`proxy/transaction_record.php`)
    .reply(200, APIMock.TransactionRecordRes);
}
export const postTransactionRecord = async (
  data: APItypes.TransactionRecordData
) => {
  return serverRequest
    .post(`proxy/transaction_record2.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.TransactionRecordResponse) ||
        ({} as APItypes.TransactionRecordResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.TransactionRecordResponse;
    });
};

// 查詢投注紀錄
if (mock) {
  mock.onPost(`proxy/betdetail.php`).reply(200, APIMock.BetDetailRes);
}
export const postBetDetail = async (data: APItypes.BetDetailData) => {
  return serverRequest
    .post(`proxy/betdetail.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.BetDetailResponse) ||
        ({} as APItypes.BetDetailResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.BetDetailResponse;
    });
};

// 查詢投注紀錄
if (mock) {
  mock.onPost(`member/bet_statistics.php`).reply(200, APIMock.BetStatisticsRes);
}
export const postBetStatistics = async (data: APItypes.BetStatisticsData) => {
  return serverRequest
    .post(`member/bet_statistics.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.BetStatisticsResponse) ||
        ({} as APItypes.BetStatisticsResponse)
      );
    })
    .catch(() => {
      return {
        type: "99",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.BetStatisticsResponse;
    });
};

// 查詢外接館餘額
if (mock) {
  mock.onPost(`proxy/api_balance.php`).reply(200, APIMock.ApiBalanceRes);
}
export const postApiBalance = async (data: APItypes.ApiBalanceData) => {
  return serverRequest
    .post(`proxy/api_balance.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.ApiBalanceResponse) ||
        ({} as APItypes.ApiBalanceResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.ApiBalanceResponse;
    });
};

// 查詢一鍵返回
if (mock) {
  mock.onPost(`member/all_money_back.php`).reply(200, APIMock.AllMoneyBackRes);
}
export const postAllMoneyBack = async (data: APItypes.AllMoneyBackData) => {
  return serverRequest
    .post(`member/all_money_back.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AllMoneyBackResponse) ||
        ({} as APItypes.AllMoneyBackResponse)
      );
    })
    .catch(() => {
      return {
        type: "99",
        msg: "接收資料異常",
      } as APItypes.AllMoneyBackResponse;
    });
};

// 代理使用
// 會員列表
if (mock) {
  mock.onPost(`proxy/user_list.php`).reply(200, APIMock.UserListRes);
}
export const postUserList = async (data: APItypes.UserListData) => {
  return serverRequest
    .post(`proxy/user_list.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.UserListResponse) ||
        ({} as APItypes.UserListResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.UserListResponse;
    });
};

// 推廣連結列表
if (mock) {
  mock.onPost(`proxy/promotion.php`).reply(200, APIMock.PromotionRes);
}
export const postPromotion = async (data: APItypes.PromotionData) => {
  return serverRequest
    .post(`proxy/promotion.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.PromotionResponse) ||
        ({} as APItypes.PromotionResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.PromotionResponse;
    });
};

// 刪除推廣連結
if (mock) {
  mock.onPost(`proxy/promotion_del.php`).reply(200, APIMock.PromotionDeleteRes);
}
export const postPromotionDelete = async (
  data: APItypes.PromotionDeleteData
) => {
  return serverRequest
    .post(`proxy/promotion_del.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.PromotionDeleteResponse) ||
        ({} as APItypes.PromotionDeleteResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.PromotionDeleteResponse;
    });
};

// 新增推廣連結
if (mock) {
  mock.onPost(`proxy/promotion_add.php`).reply(200, APIMock.PromotionAddRes);
}
export const postPromotionAdd = async (data: APItypes.PromotionAddData) => {
  return serverRequest
    .post(`proxy/promotion_add.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.PromotionAddResponse) ||
        ({} as APItypes.PromotionAddResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.PromotionAddResponse;
    });
};

// 最新消息
if (mock) {
  mock.onPost(`news/news.php`).reply(200, APIMock.NewMessageRes);
}
export const postNewMessage = async (data: APItypes.AuthData) => {
  const languages = useLanguagesStore();
  return serverRequest
    .get(`news/news.php` + "?sLang=" + languages.LanguageType)
    .then((res) => {
      // console.log(data, "data", res, "res");

      return (
        (res.data as APItypes.NewMessageResponse) ||
        ({} as APItypes.NewMessageResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      } as APItypes.NewMessageResponse;
    });
};

// 優惠活動
if (mock) {
  mock.onPost(`news/sale3.php`).reply(200, APIMock.NewMessageRes);
}
export const getSale = async (data: APItypes.AuthData) => {
  const languages = useLanguagesStore();
  return serverRequest
    .post(`news/sale3.php` + "?sLang=" + languages.LanguageType, data)
    .then((res) => {
      return (
        (res.data as APItypes.SaleResponse) || ({} as APItypes.SaleResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {} as APItypes.SaleResponseList,
      } as APItypes.SaleResponse;
    });
};
if (mock) {
  mock.onPost(`news/sale3.php`).reply(200, APIMock.NewMessageRes);
}
export const getSaleUrl = async (url: string, data: APItypes.AuthData) => {
  return axios
    .post(url, data)
    .then((res) => {
      return (
        (res.data as APItypes.SaleUrlResponse) ||
        ({} as APItypes.SaleUrlResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        sMsg: "接收資料異常",
      } as APItypes.SaleUrlResponse;
    });
};

// 代理轉帳
if (mock) {
  mock
    .onPost(`proxy/user_list_transfer.php`)
    .reply(200, APIMock.UserListTransferRes);
}
export const postUserListTransfer = async (
  data: APItypes.UserListTransferData
) => {
  return serverRequest
    .post(`proxy/user_list_transfer.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.UserListTransferResponse) ||
        ({} as APItypes.UserListTransferResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      };
    });
};

// 儲值 提領
// 查看會員有無交易密碼&銀行卡
if (mock) {
  mock.onPost(`money/check_pwd_bank.php`).reply(200, APIMock.CheckPwdBankRes);
}
export const postCheckPwdBank = async (data: APItypes.CheckPwdBankData) => {
  return serverRequest
    .post(`money/check_pwd_bank.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.CheckPwdBankResponse) ||
        ({} as APItypes.CheckPwdBankResponse)
      );
    })
    .catch(() => {
      return {
        type: "10",
        msg: "接收資料異常",
        dataList: {},
      } as APItypes.CheckPwdBankResponse;
    });
};

// 查詢管理員開啟的銀行列表
if (mock) {
  mock.onPost(`money/admin_bank_list.php`).reply(200, APIMock.AdminBankListRes);
}
export const postAdminBankList = async (data: APItypes.AdminBankListData) => {
  return serverRequest
    .post(`money/admin_bank_list.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AdminBankListResponse) ||
        ({} as APItypes.AdminBankListResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      } as APItypes.AdminBankListResponse;
    });
};

// 查詢超商綁定
if (mock) {
  mock
    .onPost(`money/check_member_store.php`)
    .reply(200, APIMock.CheckMemberStoreRes);
}
export const postCheckMemberStore = async (
  data: APItypes.CheckMemberStoreData
) => {
  return serverRequest
    .post(`money/check_member_store.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.CheckMemberStoreResponse) ||
        ({} as APItypes.CheckMemberStoreResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      } as APItypes.CheckMemberStoreResponse;
    });
};

if (mock) {
  mock
    .onPost(`money/add_member_store.php`)
    .reply(200, APIMock.AddMemberStoreRes);
}
export const postAddMemberStore = async (data: APItypes.AddMemberStoreData) => {
  return serverRequest
    .post(`money/add_member_store.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AddMemberStoreResponse) ||
        ({} as APItypes.AddMemberStoreResponse)
      );
    })
    .catch(() => {
      return {
        type: 99,
        msg: "接收資料異常",
        dataList: [],
      } as APItypes.AddMemberStoreResponse;
    });
};

// 查詢會員有無真實姓名
if (mock) {
  mock
    .onPost(`money/check_member_name.php`)
    .reply(200, APIMock.CheckMemberNameRes);
}
export const postCheckMemberName = async (
  data: APItypes.CheckMemberNameData
) => {
  return serverRequest
    .post(`money/check_member_name.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.CheckMemberNameResponse) ||
        ({} as APItypes.CheckMemberNameResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      };
    });
};

// 新增會員銀行卡

if (mock) {
  mock.onPost(`money/add_member_bank.php`).reply(200, APIMock.AddMemberBankRes);
}
// any =  APItypes.AddMemberBankData
export const postAddMemberBank = async (data: any) => {
  return serverRequest
    .post(`money/add_member_bank.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AddMemberBankResponse) ||
        ({} as APItypes.AddMemberBankResponse)
      );
    })
    .catch(() => {
      return {
        type: "4",
        msg: "接收資料異常",
      };
    });
};

// 刪除會員銀行卡(刪除後會黑名單)
if (mock) {
  mock.onPost(`money/member_bank_del.php`).reply(200, APIMock.AddMemberBankRes);
}
export const postDeleteMemberBank = async (
  data: APItypes.DeleteMemberBankData
) => {
  return serverRequest
    .post(`money/member_bank_del.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AddMemberBankResponse) ||
        ({} as APItypes.AddMemberBankResponse)
      );
    })
    .catch(() => {
      return {
        type: "4",
        msg: "接收資料異常",
      };
    });
};

// 取會員銀行卡
if (mock) {
  mock
    .onPost(`money/member_bankcard.php`)
    .reply(200, APIMock.MemberBankcardRes);
}
export const postMemberBankcard = async (data: APItypes.MemberBankcardData) => {
  return serverRequest
    .post(`money/member_bankcard.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberBankcardResponse) ||
        ({} as APItypes.MemberBankcardResponse)
      );
    })
    .catch(() => {
      return {
        type: 99,
        aBankCard: {} as APItypes.MemberBankcardABankCard,
        sMsg: "接收資料異常",
      };
    });
};

// 檢查會員USDT
// if (mock) {
//   mock
//     .onPost(`money/check_member_usdt.php`)
//     .reply(200, APIMock.CheckMemberUSDTRes);
// }
export const postCheckMemberUSDT = async (
  data: APItypes.CheckMemberUSDTData
) => {
  return serverRequest
    .post(`money/check_member_usdt.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.CheckMemberUSDTResponse) ||
        ({} as APItypes.CheckMemberUSDTResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      } as APItypes.CheckMemberUSDTResponse;
    });
};

// 新增會員USDT
// if (mock) {
//   mock.onPost(`money/add_member_usdt.php`).reply(200, APIMock.AddMemberBankRes);
// }
// any =  APItypes.AddMemberBankData
export const postAddMemberUSDT = async (data: any) => {
  return serverRequest
    .post(`money/add_member_usdt.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AddMemberUSDTResponse) ||
        ({} as APItypes.AddMemberUSDTResponse)
      );
    })
    .catch(() => {
      return {
        type: "4",
        msg: "接收資料異常",
      };
    });
};

// 提領畫面資料
if (mock) {
  mock
    .onPost(`money/member_withdrawals_page.php`)
    .reply(200, APIMock.MemberWithdrawalsPageRes);
}
export const postMemberWithdrawalsPage = async (
  data: APItypes.MemberWithdrawalsPageData
) => {
  return serverRequest
    .post(`money/member_withdrawals_page.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberWithdrawalsPageResponse) ||
        ({} as APItypes.MemberWithdrawalsPageResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {} as APItypes.MemberWithdrawalsPageList,
      };
    });
};

// 提領
// if (mock) {
//   mock
//     .onPost(`money/member_withdrawals.php`)
//     .reply(200, APIMock.MemberWithdrawalsRes);
// }
export const postMemberWithdrawals = async (
  data: APItypes.MemberWithdrawalsData
) => {
  return serverRequest
    .post(`money/member_withdrawals.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberWithdrawalsResponse) ||
        ({} as APItypes.MemberWithdrawalsResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      };
    });
};

// USDT提領畫面資料
if (mock) {
  mock
    .onPost(`money/withdrawals_usdt_page.php`)
    .reply(200, APIMock.MemberWithdrawalsPageRes);
}
export const postMemberWithdrawalsUSDTPage = async (
  data: APItypes.MemberWithdrawalsUSDTPageData
) => {
  return serverRequest
    .post(`money/member_withdrawals_usdt_page.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberWithdrawalsUSDTPageResponse) ||
        ({} as APItypes.MemberWithdrawalsUSDTPageResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {} as APItypes.MemberWithdrawalsUSDTPageList,
      };
    });
};

// USDT提領
// if (mock) {
//   mock
//     .onPost(`money/member_withdrawals_usdt.php`)
//     .reply(200, APIMock.MemberWithdrawalsRes);
// }
export const postMemberWithdrawalsUSDT = async (
  data: APItypes.MemberWithdrawalsUSDTData
) => {
  return serverRequest
    .post(`money/member_withdrawals_usdt.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberWithdrawalsUSDTResponse) ||
        ({} as APItypes.MemberWithdrawalsUSDTResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      };
    });
};

// 查詢管理員開啟的USDT列表
// if (mock) {
//   mock.onPost(`money/admin_usdt_list.php`).reply(200, APIMock.AdminUSDTListRes);
// }
export const postAdminUSDTList = async (data: APItypes.AdminUSDTListData) => {
  return serverRequest
    .post(`money/admin_usdt_list.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AdminUSDTListResponse) ||
        ({} as APItypes.AdminUSDTListResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      } as APItypes.AdminUSDTListResponse;
    });
};

// 提領列表(託售列表)
if (mock) {
  mock
    .onPost(`money/member_deposit_list.php`)
    .reply(200, APIMock.WithdrawalsRes);
}
export const PostWithdrawals = async (data: APItypes.WithdrawalsData) => {
  return serverRequest
    .post(`money/member_deposit_list.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.WithdrawalsResponse) ||
        ({} as APItypes.WithdrawalsResponse)
      );
    })
    .catch(() => {
      return {
        type: 3,
        aDepositList: {} as APItypes.AWithdrawals,
        sMsg: "接收資料異常",
      };
    });
};

// 線上儲值(畫面資料)
if (mock) {
  mock
    .onPost(`money/member_recharge_page.php`)
    .reply(200, APIMock.MemberRechargePageRes);
}
export const postMemberRechargePage = async (
  data: APItypes.MemberRechargePageData
) => {
  return serverRequest
    .post(`money/member_recharge_page.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberRechargePageResponse) ||
        ({} as APItypes.MemberRechargePageResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {} as APItypes.MemberRechargePageList,
      };
    });
};

// 線上儲值2(畫面資料)
if (mock) {
  mock
    .onPost(`money/member_recharge_page2.php`)
    .reply(200, APIMock.MemberRechargePage2Res);
}
export const postMemberRechargePage2 = async (
  data: APItypes.MemberRechargePageData
) => {
  return serverRequest
    .post(`money/member_recharge_page2.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberRechargePage2Response) ||
        ({} as APItypes.MemberRechargePage2Response)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: {} as APItypes.MemberRechargePage2List,
      };
    });
};

// 線上儲值 *待處理
// if (mock) {mock.onPost(`money/member_recharge.php`).reply(200, APIMock.MemberRechargeRes)}
export const postMemberRecharge = async (data: APItypes.MemberRechargeData) => {
  return serverRequest
    .post(`money/member_company_deposit.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberRechargeResponse) ||
        ({} as APItypes.MemberRechargeResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      };
    });
};

// 銀行轉帳(畫面資料)
if (mock) {
  mock
    .onPost(`money/member_company_deposit_page.php`)
    .reply(200, APIMock.MemberCompanyDepositPageRes);
}
export const postMemberCompanyDepositPage = async (
  data: APItypes.MemberCompanyDepositPageData
) => {
  return serverRequest
    .post(`money/member_company_deposit_page.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberCompanyDepositPageResponse) ||
        ({} as APItypes.MemberCompanyDepositPageResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
        dataList: [],
      };
    });
};

// 銀行轉帳
if (mock) {
  mock
    .onPost(`money/member_company_deposit.php`)
    .reply(200, APIMock.MemberCompanyDepositRes);
}
export const postMemberCompanyDeposit = async (
  data: APItypes.MemberCompanyDepositData
) => {
  return serverRequest
    .post(`money/member_company_deposit.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberCompanyDepositResponse) ||
        ({} as APItypes.MemberCompanyDepositResponse)
      );
    })
    .catch(() => {
      return {
        type: "3",
        msg: "接收資料異常",
      };
    });
};

// 充提列表(儲值列表)
if (mock) {
  mock
    .onPost(`money/member_deposit_list.php`)
    .reply(200, APIMock.DepositListRes);
}
export const PostDepositList = async (data: APItypes.DepositListData) => {
  return serverRequest
    .post(`money/member_deposit_list.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.DepositListResponse) ||
        ({} as APItypes.DepositListResponse)
      );
    })
    .catch(() => {
      return {
        type: 3,
        aDepositList: {} as APItypes.ADepositList,
        sMsg: "接收資料異常",
      };
    });
};

// Usdt入款(畫面資料)
// if (mock) {
//   mock
//     .onPost(`money/member_usdt_deposit_page.php`)
//     .reply(200, APIMock.MemberUSDTDepositPageRes);
// }
export const postMemberUSDTDepositPage = async (
  data: APItypes.MemberUSDTDepositPageData
) => {
  return serverRequest
    .post(`money/member_usdt_deposit_page.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberUSDTDepositPageResponse) ||
        ({} as APItypes.MemberUSDTDepositPageResponse)
      );
    })
    .catch(() => {
      return {
        type: "99",
        msg: "接收資料異常",
        dataList: {} as APItypes.MemberUSDTDepositPageDataList,
      };
    });
};

// Usdt入款
// if (mock) {
//   mock
//     .onPost(`money/member_usdt_deposit.php`)
//     .reply(200, APIMockMemberUSDTDepositRes);
// }
export const postMemberUSDTDeposit = async (
  data: APItypes.MemberUSDTDepositData
) => {
  return serverRequest
    .post(`money/member_usdt_deposit.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberUSDTDepositResponse) ||
        ({} as APItypes.MemberUSDTDepositResponse)
      );
    })
    .catch(() => {
      return {
        type: "99",
        msg: "接收資料異常",
      };
    });
};

// Usdt入款資訊
// if (mock) {
//   mock
//     .onPost(`money/member_usdt_deposit_detail.php`)
//     .reply(200, APIMockMemberUSDTDepositDetailRes);
// }
export const postMemberUSDTDepositDetail = async (
  data: APItypes.MemberUSDTDepositDetailData
) => {
  return serverRequest
    .post(`money/member_usdt_deposit_detail.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.MemberUSDTDepositDetailResponse) ||
        ({} as APItypes.MemberUSDTDepositDetailResponse)
      );
    })
    .catch(() => {
      return {
        type: "99",
        msg: "接收資料異常",
        dataList: {
          aDetail: {} as APItypes.MemberUSDTDepositDetailaDetail,
        },
      };
    });
};

// 實名認證棄用
if (mock) {
  mock.onPost(`money/auth_bankcard.php`).reply(200, APIMock.AuthBankCardRes);
}
// data: any = APItypes.AuthBankCardData
export const PostAuthBankCard = async (data: any) => {
  return serverRequest
    .post(`money/auth_bankcard.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.AuthBankCardResponse) ||
        ({} as APItypes.AuthBankCardResponse)
      );
    })
    .catch(() => {
      return {
        nError: 99,
        sMsg: "接收資料異常",
      };
    });
};

// 實名認證
if (mock) {
  mock
    .onPost(`member/verifyrealname.php`)
    .reply(200, APIMock.VerifyRealNameRes);
}
// data: any = APItypes.AuthBankCardData
export const PostVerifyRealName = async (data: any) => {
  return serverRequest
    .post(`member/verifyrealname.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.VerifyRealNameResponse) ||
        ({} as APItypes.VerifyRealNameResponse)
      );
    })
    .catch(() => {
      return {
        type: "99",
        nError: "接收資料異常",
        sMsg: "接收資料異常",
      };
    });
};

// 排行榜
if (mock) {
  mock.onPost(`member/leaderboard.php`).reply(200, APIMock.DepositListRes);
}
export const PostLeaderBoard = async (data: APItypes.LeaderBoardData) => {
  return serverRequest
    .post(`member/leaderboard.php`, data)
    .then((res) => {
      return (
        (res.data as APItypes.LeaderBoardResponse) ||
        ({} as APItypes.LeaderBoardResponse)
      );
    })
    .catch(() => {
      return {
        type: 99,
        aLeaderboard: {} as APItypes.LeaderBoardListData,
        sMsg: "接收資料異常",
      };
    });
};

// 取得Line連結
if (mock) {
  mock
    .onGet(`talk/web_talk_line_service.php`)
    .reply(200, APIMock.WebTalkLineServiceRes);
}
export const GetWebTalkLineService = async () => {
  return serverRequest
    .get(`talk/web_talk_line_service.php`)
    .then((res) => {
      return (
        (res.data as APItypes.WebTalkLineServiceResponse) ||
        ({} as APItypes.WebTalkLineServiceResponse)
      );
    })
    .catch(() => {
      return {
        sLink: "",
        sImage: "",
        sMsg: "接收資料異常",
      };
    });
};
