<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useTimeListLogic } from "@/stores/timeListLogic";
import search_btn from "@image/memberFunction/08/search_btn.png";
import 會員選擇無 from "@image/memberFunction/common/會員選擇無.png";
import 會員選擇 from "@image/memberFunction/common/會員選擇.png";
import search_bg_head from "@image/memberFunction/03/search_bg_head.png";
import 頁面跳轉 from "@image/memberFunction/common/頁面跳轉.png";
import no_file from "@image/memberFunction/文字框/no_file.png";
const { t } = useI18n();
const memberService = useMemberService();
const store = useCounterStore();
const { defaultWithdrawals } = storeToRefs(memberService);
const timeListLogic = useTimeListLogic();
function changeStartTime() {
  if (
    !defaultWithdrawals.value.sEndTime ||
    defaultWithdrawals.value.sEndTime <= defaultWithdrawals.value.sStartTime
  ) {
    const getStartTime = new Date(defaultWithdrawals.value.sStartTime);
    getStartTime.setMonth(getStartTime.getMonth() + 1);
    defaultWithdrawals.value.sEndTime = getStartTime.toISOString().slice(0, 10);
  }
}

//#region 點擊查詢時間
interface TimeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const timeLists = ref<TimeList[]>([
  {
    title: t("MemberFunction.BetRecord.Yesterday"),
    fn: getYesterdayDepositList,
    isSelect: false,
  },
  {
    title: t("MemberFunction.BetRecord.Today"),
    fn: getTodayDepositList,
    isSelect: true,
  },
  {
    title: t("MemberFunction.BetRecord.LastWeek"),
    fn: getLastWeekDepositList,
    isSelect: false,
  },
  {
    title: t("MemberFunction.BetRecord.ThisWeek"),
    fn: getThisWeekDepositList,
    isSelect: false,
  },
  {
    title: t("MemberFunction.BetRecord.ThisMonth"),
    fn: getThisMonthDepositList,
    isSelect: false,
  },
]);
function selectTime(index: number) {
  timeLists.value.forEach((time, timeIndex) => {
    time.isSelect = false;
    if (timeIndex === index) {
      time.isSelect = true;
    }
  });
}

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 31);
const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const minDate = ref(formatDate(thirtyDaysAgo));
const maxDate = ref(formatDate(today));

function getYesterdayDepositList() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day - 1);
  defaultWithdrawals.value.sStartTime = timeListLogic.getDataDate(day - 1);
  defaultWithdrawals.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(0);
  getWithdrawalsData();
}
function getTodayDepositList() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day);
  defaultWithdrawals.value.sStartTime = timeListLogic.getDataDate(day);
  defaultWithdrawals.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(1);
  getWithdrawalsData();
}
function getLastWeekDepositList() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(0);
  defaultWithdrawals.value.sStartTime = timeListLogic.getDataDate(-6);
  defaultWithdrawals.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(2);
  getWithdrawalsData();
}
function getThisWeekDepositList() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(7);
  defaultWithdrawals.value.sStartTime = timeListLogic.getDataDate(1);
  defaultWithdrawals.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(3);
  getWithdrawalsData();
}

function getThisMonthDepositList() {
  defaultWithdrawals.value.sStartTime = timeListLogic.getMonthFirthDate();
  defaultWithdrawals.value.sEndTime = timeListLogic.getMonthLastDate();

  selectTime(4);
  getWithdrawalsData();
}
//#endregion

function goWithdrawalsPage(page: number) {
  memberService.goWithdrawalsPage(page);
}

async function getWithdrawalsData(search = false) {
  if (search) {
    timeLists.value = timeLists.value.map((item) => {
      return {
        ...item,
        isSelect: false,
      };
    });
  }
  await memberService.getWithdrawalsData();
}

// onMounted(async () => {
//   await nextTick();
//   memberService.getWithdrawalsData();
// });
</script>
<template>
  <div class="px-[1em] md:px-[5dvh]">
    <div class="flex flex-col md:flex-row items-center">
      <div
        class="flex justify-between items-center w-full mt-[2dvh] my-0 md:my-[2dvh] col-span-3">
        <label
          for="memberNumber"
          class="search-date-label relative w-full md:w-[16vw]">
          <input
            @change="changeStartTime"
            :min="minDate"
            :max="maxDate"
            type="date"
            v-model="defaultWithdrawals.sStartTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <span class="text-primary text-[12px] md:text-[14px] mx-[12px]"
          >至</span
        >
        <label
          for="memberNumber"
          class="search-date-label relative w-full md:w-[16vw]">
          <input
            type="date"
            :min="minDate"
            :max="maxDate"
            v-model="defaultWithdrawals.sEndTime"
            class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
        </label>
        <button @click="getWithdrawalsData(true)" class="ml-[12px]">
          <img v-img-src="search_btn" alt="" class="h-auto md:h-[3dvh]" />
        </button>
      </div>
    </div>
    <ul class="flex justify-center md:justify-between items-center mt-[1dvh]">
      <li
        v-for="time in timeLists"
        :key="time.title + 'berRecord'"
        class="flex justify-center md:justify-start items-center">
        <button
          class="group relative w-[100%] md:w-[6em] xl:w-[7em] text-[10px] md:text-[14px] mr-0"
          @click="time.fn"
          :class="{
            'text-primary': !time.isSelect,
            'text-secondary': time.isSelect,
          }">
          <img v-img-src="會員選擇無" alt="" v-show="!time.isSelect" />
          <img v-img-src="會員選擇" alt="" v-show="time.isSelect" />
          <p
            class="font-bold absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
            {{ time.title }}
          </p>
        </button>
      </li>
    </ul>
    <div class="w-full h-[3px] bg-white/80 my-[2dvh] hidden md:block"></div>
    <div
      class="relative rounded-lg py-[0px] px-[0] min-h-[60dvh] md:min-h-[70dvh]">
      <div class="text-primary text-[12px] md:text-[14px] w-full">
        <div class="overflow-auto">
          <table class="w-[100%] md:w-full table-auto">
            <thead>
              <tr>

                <th class="w-[20%] h-[45px]">
                  {{ $t("MemberFunction.Withdraw.RechargeType") }}
                </th>
                <th class="w-[20%] h-[45px]">
                  {{ $t("MemberFunction.Withdraw.Amount") }}
                </th>
                <th class="h-[45px]">
                  {{ $t("MemberFunction.Withdraw.Times") }}
                </th>
                <th class="w-[12%] h-[45px]">
                  {{ $t("MemberFunction.Withdraw.Status") }}
                </th>
                <th class="w-[20%] h-[45px]">
                  {{ $t("MemberFunction.Withdraw.Notes") }}
                </th>                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in memberService.CurrentWithdrawals"
                :key="item.nId"
                class="relative bg-black/50 first:after:h-[0px] after:absolute after:h-[1px] after:bg-white after:left-1/2 after:-translate-x-1/2 after:w-[calc(100%-12px)]">
                <td class="w-[20%] h-[45px] text-center">
                  {{ item.sPayType }}
                </td>
                <td class="w-[20%] h-[45px] text-center text-green-400">
                  {{
                    store.getClearPotAndAddCommas(
                      parseInt(item.nMoney).toString()
                    )
                  }}
                </td>
                <td class="h-[45px] text-center">
                  {{ item.sCreateTime }}
                </td>
                <td
                  class="w-[12%] h-[45px] text-center"
                  :class="{
                    'text-green-600': item.sStatus === '通過',
                    'text-yellow-300': item.sStatus === '未審核',
                    'text-red-600': item.sStatus === '拒絕',
                  }">
                  {{ item.sStatus }}
                </td>
                <td class="w-[20%] h-[45px] pl-[12px] md:pl-0 text-center">
                  {{ item.sAbout }}
                </td>                
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="h-[45px] text-center pl-[6px] lg:pl-[2em]"></th>
              </tr>
            </tfoot>
          </table>
        </div>

        <div
          class="flex items-center justify-center absolute bottom-[10px] left-1/2 -translate-x-1/2 text-[12px]">
          <button
            type="button"
            :disabled="memberService.UserListPage === 1"
            @click="goWithdrawalsPage(memberService.UserListPage - 1)"
            class="w-[10px]">
            <img v-img-src="頁面跳轉" alt="" />
          </button>
          <ul class="flex text-primary">
            <li
              v-for="index in memberService.TotalWithdrawalsPage"
              :key="`page${index}`"
              class="mx-[6px]">
              <button
                @click="goWithdrawalsPage(index)"
                class="font-bold"
                :class="
                  index === memberService.WithdrawalsPage
                    ? 'text-primary'
                    : 'text-primary/50 hover:text-primary'
                "
                :disabled="index === memberService.WithdrawalsPage">
                {{ index }}
              </button>
            </li>
          </ul>
          <button
            type="button"
            class="rotate-180 w-[10px]"
            :disabled="
              memberService.WithdrawalsPage ===
              memberService.TotalWithdrawalsPage
            "
            @click="goWithdrawalsPage(memberService.WithdrawalsPage + 1)">
            <img v-img-src="頁面跳轉" alt="" />
          </button>
        </div>
      </div>
      <!-- 沒有資料 -->
      <div
        class="absolute left-1/2 top-3/1 -translate-x-1/2 flex flex-col items-center"
        v-if="memberService.CurrentWithdrawals.length === 0">
        <img v-img-src="no_file" alt="" class="w-[35px]" />
        <p class="text-primary text-[12px] text-center">
          {{ $t("MemberFunction.BetRecord.NoData") }}
        </p>
      </div>
    </div>
  </div>
</template>
