<script setup lang="ts">
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import type { FormContext, Form } from "vee-validate";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
const login = useLogin();
const store = useCounterStore();
const memberService = useMemberService();
const { hasTradePwd } = storeToRefs(memberService);

const emit = defineEmits(["toggleAlert"]);

interface ChangePasswordFormValues {
  sOld_Password: string;
  sNew_Password: string;
  sConfirm_Password: string;
}

function closeAlert() {
  console.log("toggleAlert");
  emit("toggleAlert");
}

const changePasswordForm = ref<null | InstanceType<typeof Form>>(null);
const nPasswordType = ref<string>("1");
async function changePasswordSubmit(
  data: ChangePasswordFormValues,
  actions: FormContext
) {
  const { sOld_Password, sNew_Password, sConfirm_Password } = data;
  const m_user_c = login.CookieMUC();
  const postData = {
    sOld_Password,
    sNew_Password,
    sConfirm_Password,
    nPassword_type: nPasswordType.value,
    m_user_c,
  };

  const resType = await memberService.ChangePassword(postData);
  let keepType = nPasswordType.value;
  switch (resType) {
    case "0":
      await actions.resetForm();
      nPasswordType.value = keepType;
      break;
    default:
      break;
  }
}
</script>
<template>
  <div class="sticky top-[10%] md:top-[10%] mx-auto w-[95vw] md:w-[500px]">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100">
      <h3
        class="absolute top-[16px] md:top-[18px] left-1/2 -translate-x-1/2 text-center text-secondary text-[18px] md:text-[20px] font-bold">
        {{ $t("MemberFunction.ChangePassword.ChangePassword") }}
      </h3>

      <div
        class="py-[3em] px-[2em] w-full h-full bg-100 text-primary flex flex-col text-[14px]">
        <VForm
          ref="changePasswordForm"
          @submit="changePasswordSubmit"
          v-slot="{ meta, isSubmitting, errors }"
          class="p-[12px] top-0 text-primary flex flex-col">
          <label
            :for="$t('MemberFunction.ChangePassword.OldPassword')"
            class="flex items-center justify-between relative text-[14px] md:text-[16px] mt-[2em] mx-[1vw]">
            <p class="mr-[1em]">
              {{ $t("MemberFunction.ChangePassword.OldPassword") }}
            </p>
            <VField
              class="col-span-2 lg:col-span-1 bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded px-[10px] placeholder:text-[14px]"
              :label="$t('MemberFunction.ChangePassword.OldPassword')"
              name="sOld_Password"
              type="password"
              :rules="{
                regex: /^[a-zA-Z\d]{6,16}$/,
                required: true,
              }" />
            <!-- <ErrorMessage
              name="sOld_Password"
              class="text-primary text-[14px] text-center"
            /> -->
          </label>
          <label
            :for="$t('MemberFunction.ChangePassword.NewPassword')"
            class="flex items-center justify-between relative text-[14px] md:text-[16px] mt-[2em] mx-[1vw]">
            <p class="mr-[1em]">
              {{ $t("MemberFunction.ChangePassword.NewPassword") }}
            </p>
            <VField
              id="sNew_Password"
              class="col-span-2 lg:col-span-1 bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded px-[10px] placeholder:text-[14px]"
              :label="$t('MemberFunction.ChangePassword.NewPassword')"
              name="sNew_Password"
              type="password"
              :placeholder="
                $t('MemberFunction.ChangePassword.NewPasswordPlaceHolder')
              "
              :rules="{
                regex: /^[a-zA-Z\d]{6,16}$/,
                required: true,
              }" />
            <!-- <ErrorMessage
              name="sNew_Password"
              class="text-primary text-[14px] text-center"
            /> -->
          </label>
          <label
            :for="$t('MemberFunction.ChangePassword.CheckNewPassword')"
            class="flex items-center justify-between text-[14px] md:text-[16px] mt-[2em] mx-[1vw]">
            <p class="mr-[1em]">
              {{ $t("MemberFunction.ChangePassword.CheckNewPassword") }}
            </p>
            <VField
              class="col-span-2 lg:col-span-1 bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded px-[10px] placeholder:text-[14px]"
              :label="$t('MemberFunction.ChangePassword.CheckNewPassword')"
              name="sConfirm_Password"
              type="password"
              :placeholder="
                $t('MemberFunction.ChangePassword.CheckNewPasswordPlaceHolder')
              "
              :rules="{
                regex: /^[a-zA-Z\d]{6,16}$/,
                required: true,
                confirmed: '@sNew_Password',
              }" />
            <!-- <ErrorMessage
              name="sConfirm_Password"
              class="text-primary text-[14px] text-center"
            /> -->
          </label>

          <p class="text-red-400 text-center">
            {{ memberService.changepwdMsg }}
          </p>
          <p class="text-red-400 text-center mt-[36px] mb-[12px]">
            {{
              errors.sOld_Password
                ? errors.sOld_Password
                : errors.sNew_Password
                ? errors.sNew_Password
                : errors.sConfirm_Password
                ? errors.sConfirm_Password
                : ""
            }}
          </p>
          <button
            type="submit"
            :disabled="!meta.valid || isSubmitting"
            class="btn-y-border text-secondary self-center"
            :class="{ submitting: isSubmitting }">
            <p class="px-[2em] py-[.1em] text-[14px]">
              {{
                isSubmitting ? "" : $t("MemberFunction.ChangePassword.Submit")
              }}
            </p>
          </button>
        </VForm>
      </div>
      <button
        type="button"
        @click="closeAlert"
        class="absolute text-black text-[18px] md:text-[16px] top-[16px] md:top-[20px] right-[34px] md:right-[38px] font-bold">
        Ｘ
      </button>
    </div>
  </div>
</template>
