<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "swiper/css/navigation";

import type { VipListList } from "@/assets/API/APItype";

import VipLevel from "@/components/menberFunction/VIPPrivilege/VipLevel.vue";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

import VIPCardImage1 from "@image/memberFunction/16/card1.png";
import VIPCardImage2 from "@image/memberFunction/16/card2.png";
import VIPCardImage3 from "@image/memberFunction/16/card3.png";
import VIPCardImage4 from "@image/memberFunction/16/card4.png";
import VIPCardImage5 from "@image/memberFunction/16/card5.png";
import VIPCardImage6 from "@image/memberFunction/16/card6.png";
import VIPCardImage7 from "@image/memberFunction/16/card7.png";
import VIPCardImage8 from "@image/memberFunction/16/card8.png";
import VIPCardImage9 from "@image/memberFunction/16/card9.png";

import VIPFlagImage1 from "@image/memberFunction/common/VIP1.png";
import VIPFlagImage2 from "@image/memberFunction/common/VIP2.png";
import VIPFlagImage3 from "@image/memberFunction/common/VIP3.png";
import VIPFlagImage4 from "@image/memberFunction/common/VIP4.png";
import VIPFlagImage5 from "@image/memberFunction/common/VIP5.png";
import VIPFlagImage6 from "@image/memberFunction/common/VIP6.png";
import VIPFlagImage7 from "@image/memberFunction/common/VIP7.png";
import VIPFlagImage8 from "@image/memberFunction/common/VIP8.png";
import VIPFlagImage9 from "@image/memberFunction/common/VIP9.png";

import title_line from "@image/memberFunction/03/title_line.png";
import my_top_bg from "@image/memberFunction/05/my_top_bg.png";
import level_award_icon from "@image/memberFunction/16/level_award_icon.png";
import week_award_icon from "@image/memberFunction/16/week_award_icon.png";
import birthday_award_icon from "@image/memberFunction/16/birthday_award_icon.png";

const modules = [Navigation, Pagination, Scrollbar];

const router = useRouter();
const memberService = useMemberService();
const login = useLogin();
const store = useCounterStore();
const { VipListSort } = storeToRefs(memberService);
const { memberData, isZeroVipLevel } = storeToRefs(login);

const getVIPCardImage = (level: string) => {
  let image;
  switch (level) {
    case "1":
      image = VIPCardImage1;
      break;
    case "2":
      image = VIPCardImage2;
      break;
    case "3":
      image = VIPCardImage3;
      break;
    case "4":
      image = VIPCardImage4;
      break;
    case "5":
      image = VIPCardImage5;
      break;
    case "6":
      image = VIPCardImage6;
      break;
    case "7":
      image = VIPCardImage7;
      break;
    case "8":
      image = VIPCardImage8;
      break;
    case "9":
      image = VIPCardImage9;
      break;
    default:
      image = VIPCardImage9;
      break;
  }
  return image;
};

const getVIPIconImage = (level: string) => {
  let image;
  switch (level) {
    case "0":
    case "1":
      // image = VIPIconImage1;
      image = VIPFlagImage1;
      break;
    case "2":
      // image = VIPIconImage2;
      image = VIPFlagImage2;
      break;
    case "3":
      // image = VIPIconImage3;
      image = VIPFlagImage3;
      break;
    case "4":
      // image = VIPIconImage4;
      image = VIPFlagImage4;
      break;
    case "5":
      // image = VIPIconImage5;
      image = VIPFlagImage5;
      break;
    case "6":
      // image = VIPIconImage6;
      image = VIPFlagImage6;
      break;
    case "7":
      // image = VIPIconImage7;
      image = VIPFlagImage7;
      break;
    case "8":
      // image = VIPIconImage8;
      image = VIPFlagImage8;
      break;
    case "9":
      // image = VIPIconImage9;
      image = VIPFlagImage9;
      break;
    default:
      image = "";
      break;
  }
  return image;
};
const NextVIP = computed(() => {
  if (!memberData.value.aVipData) return {} as VipListList;
  const NextLv = (parseInt(memberData.value.aVipData.nVipLv) + 1).toString();
  const findVIP = VipListSort.value.find((item) => {
    return item.nVipLv === NextLv;
  });
  if (findVIP) return findVIP;
  return {} as VipListList;
});

// Swiper
const VIPswiper = ref(null);
const NowSwiperVip = ref<VipListList>({} as VipListList);
function setVIPSwiper(swiper: any) {
  VIPswiper.value = swiper;
}
function onSlideChange(swiper: any) {
  const currentSlideIndex = swiper.activeIndex; // 目前 slide 的索引
  NowSwiperVip.value = VipListSort.value[currentSlideIndex]; // 取得目前 vip;
}

function toService() {
  router.push({ name: "Service" });
}
watch(VipListSort, () => {
  // @ts-ignore
  const currentSlideIndex = VIPswiper.value ? VIPswiper.value.activeIndex : 0;
  NowSwiperVip.value = VipListSort.value[currentSlideIndex];
});
onMounted(() => {
  console.log(VIPswiper.value, "NowSwiperVip.value");
  if (VIPswiper.value) {
    //@ts-ignore
    VIPswiper.value.slideTo(memberData.value.aVipData.nVipLv - 1); // 通过索引跳转
  }
});
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-0 md:px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9">
      <div
        class="px-0 pt-0 pb-[72px] md:px-[5vw] md:pt-[2dvh] md:pb-[8dvh] relative z-10 web-gold-border rounded-[24px]">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.VIPPrivilege.VIPPrivilege") }}
        </p>
        <div class="">
          <div class="items-center mt-[2vh] hidden md:flex">
            <img v-img-src="title_line" alt="" class="h-[20px] mr-[6px]" />
            <p class="text-[18px] text-primary">
              {{ $t("MemberFunction.VIPPrivilege.CurrentLevel") }}
            </p>
          </div>
          <div class="w-full md:w-[60vw] xl:w-[30vw] mx-auto">
            <img
              v-img-src="my_top_bg"
              alt=""
              class="absolute z-10 top-[-6.5dvh] h-[calc(20dvh+6dvh)] w-full block sm:hidden" />
            <div class="m-[24px] mt-[12px] text-primary relative z-20">
              <div class="grid grid-cols-8">
                <p class="text-[16px] md:text-[18px] col-span-2 md:col-span-1">
                  {{ memberData.sAccount }}
                </p>
                <div
                  class="mx-[1.5vw] col-span-2 w-1/2"
                  v-if="memberData.aVipData?.nVipLv">
                  <img
                    v-img-src="getVIPIconImage(memberData.aVipData.nVipLv)"
                    alt=""
                    class="w-full" />
                </div>
              </div>
              <div class="grid grid-cols-8 gap-y-[12px] text-[16px] mt-[12px]">
                <p class="col-span-2 md:col-span-1 md-0 text-[14px] leading-4">
                  {{ $t("MemberFunction.VIPPrivilege.CurrentWagering") }}
                </p>
                <p class="col-span-3 ml-0 md:ml-[24px]">
                  <span class="text-yellow-200">
                    {{
                      memberData.aVipData
                        ? store.getClearPotAndAddCommas(
                            memberData.aVipData.nBetMoney
                          )
                        : ""
                    }}
                  </span>
                  /
                  {{ store.addCommas(NextVIP.nVipBet) }}
                </p>
                <p
                  class="whitespace-nowrap col-span-3 md:col-span-3 text-end self-center text-[12px] md:text-[14px]">
                  {{ $t("MemberFunction.VIPPrivilege.CurrentWagering") }}
                  {{ memberData.aVipData ? memberData.aVipData.nKeepDay : 0 }}
                  {{ $t("MemberFunction.VIPPrivilege.Day") }}
                </p>
                <VipLevel class="col-span-8" v-if="!isZeroVipLevel" />
              </div>
            </div>
          </div>
          <div
            class="relative px-[1em] md:px-0 translate-y-[calc(0)] top-[7vh] md:top-0">
            <Swiper
              key="VIPswiper"
              :slides-per-view="1"
              :space-between="50"
              :modules="modules"
              :pagination="{ clickable: true }"
              :navigation="{ prevEl: `.button-prev`, nextEl: `.button-next` }"
              @slideChange="onSlideChange"
              @swiper="setVIPSwiper"
              class="w-full md:w-[60vw] xl:w-[30vw] mx-auto">
              <SwiperSlide v-for="item in VipListSort" :key="item.nId">
                <div class="relative" :data-vip-lv="item.nVipLv">
                  <img
                    v-img-src="getVIPCardImage(item.nVipLv)"
                    alt=""
                    class="" />
                  <p
                    class="text-primary text-[18px] absolute top-[12px] right-[12px]">
                    {{ item.sVipName }}
                  </p>
                  <div
                    class="absolute bottom-1 grid grid-cols-2 w-full text-[14px] text-primary">
                    <div class="text-center">
                      <p class="text-[18px] font-bold text-card">
                        {{ store.addCommas(item.nVipBet) }}
                      </p>
                      <p class="text-[14px] md:text-[18px] text-card">
                        {{
                          $t("MemberFunction.VIPPrivilege.TurnoverRequirement")
                        }}
                      </p>
                    </div>
                    <div class="text-center">
                      <p class="text-[18px] font-bold text-card">
                        {{ store.addCommas(item.nKeepValue) }}
                      </p>
                      <p class="text-[14px] md:text-[18px] text-card">
                        {{ $t("MemberFunction.VIPPrivilege.SaveTurnover") }}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="hidden md:flex items-center mt-0 md:mt-[3em]">
              <img v-img-src="title_line" alt="" class="h-[20px] mr-[6px]" />
              <p class="text-[18px] text-primary" v-if="NowSwiperVip">
                {{ NowSwiperVip.sVipName }}
                {{ $t("MemberFunction.VIPPrivilege.Privileged") }}
              </p>
            </div>
            <div
              class="relative vip-privilege-bg-border text-primary w-full h-full px-[1em] py-[1em] md:py-[3em] mt-[12px]">
              <div class="flex items-center md:hidden">
                <img v-img-src="title_line" alt="" class="h-[20px] mr-[12px]" />
                <p class="text-[18px]" v-if="NowSwiperVip">
                  {{ NowSwiperVip.sVipName }}
                  {{ $t("MemberFunction.VIPPrivilege.Privileged") }}
                </p>
              </div>
              <div class="grid grid-cols-3 gap-[12px] mt-[12px] tracking-[2px]">
                <button
                  class="flex flex-col justify-between items-center text-primary"
                  @click="toService">
                  <p class="text-[16px] leading-[18px]">
                    {{ $t("MemberFunction.VIPPrivilege.UpgradeBonus") }}
                  </p>
                  <p class="text-[12px] text-primary/50">　</p>
                  <img
                    v-img-src="level_award_icon"
                    alt=""
                    class="w-[60px] my-[6px]" />
                  <p
                    class="text-[18px] md:text-[24px] text-coin"
                    v-if="NowSwiperVip">
                    {{ NowSwiperVip.nLvUpMoney }}
                  </p>
                </button>
                <button
                  class="flex flex-col justify-between items-center text-primary"
                  @click="toService">
                  <p class="text-[16px] leading-[18px]">
                    {{ $t("MemberFunction.VIPPrivilege.WeeklyBenefits") }}
                  </p>
                  <p class="text-[12px] text-primary/50">　</p>
                  <img
                    v-img-src="week_award_icon"
                    alt=""
                    class="w-[60px] my-[6px]" />
                  <p
                    class="text-[18px] md:text-[24px] text-coin"
                    v-if="NowSwiperVip">
                    {{ NowSwiperVip.nWeekProfit }}
                  </p>
                </button>
                <button
                  class="flex flex-col justify-between items-center text-primary"
                  @click="toService">
                  <p class="text-[16px] leading-[18px] text-center">
                    {{ $t("MemberFunction.VIPPrivilege.BirthdayBonus") }}
                  </p>
                  <p class="text-[12px] text-red-600 w-[120%]">
                    {{ $t("MemberFunction.VIPPrivilege.CustomerService") }}
                  </p>
                  <img
                    v-img-src="birthday_award_icon"
                    alt=""
                    class="w-[60px] my-[6px]" />
                  <p
                    class="text-[18px] md:text-[24px] text-coin"
                    v-if="NowSwiperVip">
                    {{ NowSwiperVip.nBirthdayMoney }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-100 {
  background-size: 100% 100%;
}
.swiper-pagination {
  position: static !important;
  margin-top: 6px !important;
}
.swiper-pagination-bullet {
  display: inline-block !important;
  border-radius: 3px;
  margin: 3px !important;
  width: 10px !important;
  height: 6px !important;
  background-color: rgb(247, 247, 247) !important;
}

/* change color of previous bullet to white*/

.swiper-pagination-bullet-active {
  width: 18px !important;
  height: 6px !important;
  background-color: white !important;
}

.vip-privilege-bg-border {
  border-style: solid;
  border-width: 11px;
  border-radius: 10px;
  -moz-border-image: url("@image/memberFunction/common/share_bg.png") 3;
  -webkit-border-image: url("@image/memberFunction/common/share_bg.png") 3;
  -o-border-image: url("@image/memberFunction/common/share_bg.png") 3;
  border-image: url("@image/memberFunction/common/share_bg.png") 20 fill;
}
</style>
