<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useTimeListLogic } from "@/stores/timeListLogic";
import search_btn from "@image/memberFunction/08/search_btn.png";
import 會員選擇無 from "@image/memberFunction/common/會員選擇無.png";
import 會員選擇 from "@image/memberFunction/common/會員選擇.png";
import 頁面跳轉 from "@image/memberFunction/common/頁面跳轉.png";
import no_file from "@image/memberFunction/文字框/no_file.png";

const { t } = useI18n();
const store = useCounterStore();
const memberService = useMemberService();
const timeListLogic = useTimeListLogic();
const { defaultTransactionRecord } = storeToRefs(memberService);

function changeStartTime() {
  if (
    !defaultTransactionRecord.value.sEndTime ||
    defaultTransactionRecord.value.sEndTime <=
      defaultTransactionRecord.value.sStartTime
  ) {
    defaultTransactionRecord.value.sEndTime =
      defaultTransactionRecord.value.sStartTime;
  }
}

//#region 點擊查詢時間
interface TimeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const timeLists = ref<TimeList[]>([
  {
    title: t("MemberFunction.TradeRecord.Yesterday"),
    fn: getYesterdayTradeRecord,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.Today"),
    fn: getTodayTradeRecord,
    isSelect: true,
  },
  {
    title: t("MemberFunction.TradeRecord.LastWeek"),
    fn: getLastWeekTradeRecord,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.ThisWeek"),
    fn: getThisWeekTradeRecord,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.ThisMonth"),
    fn: getThisMonthTradeRecord,
    isSelect: false,
  },
]);
function selectTime(index: number) {
  timeLists.value.forEach((time, timeIndex) => {
    time.isSelect = false;
    if (timeIndex === index) {
      time.isSelect = true;
    }
  });
}

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 31);
const formatDate = (date: any) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const minDate = ref(formatDate(thirtyDaysAgo));
const maxDate = ref(formatDate(today));

function getYesterdayTradeRecord() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day - 1);
  defaultTransactionRecord.value.sStartTime = timeListLogic.getDataDate(
    day - 1
  );
  defaultTransactionRecord.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(0);
  getTradeRecordData();
}
function getTodayTradeRecord() {
  const today = new Date();
  const day = today.getDay();
  const EndDay = timeListLogic.getCashTypeEndTimeDay(day);
  defaultTransactionRecord.value.sStartTime = timeListLogic.getDataDate(day);
  defaultTransactionRecord.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(1);
  getTradeRecordData();
}

function getLastWeekTradeRecord() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(0);
  defaultTransactionRecord.value.sStartTime = timeListLogic.getDataDate(-6);
  defaultTransactionRecord.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(2);
  getTradeRecordData();
}

function getThisWeekTradeRecord() {
  const EndDay = timeListLogic.getCashTypeEndTimeDay(7);
  defaultTransactionRecord.value.sStartTime = timeListLogic.getDataDate(1);
  defaultTransactionRecord.value.sEndTime = timeListLogic.getDataDate(EndDay);

  selectTime(3);
  getTradeRecordData();
}

function getThisMonthTradeRecord() {
  defaultTransactionRecord.value.sStartTime = timeListLogic.getMonthFirthDate();
  defaultTransactionRecord.value.sEndTime = timeListLogic.getMonthLastDate();

  selectTime(4);
  getTradeRecordData();
}
//#endregion

function goTradeRecordPage(page: number) {
  memberService.goTradeRecordPage(page);
}

function getTradeRecordData() {
  memberService.getTradeRecordData();
}

function openTradeRecordAlert(nId: string) {
  store.toggleAlertBg();
  store.openTradeRecordAlert();
  memberService.getTradeRecordAlertContent(nId);
}
</script>
<template>
  <div class="px-[0] mb-[8dvh] md:px-[5dvh]">
    <div
      class="flex flex-col md:flex-row items-center px-[1em] md:px-0 pt-0 md:pt-[1vh]">
      <div class="flex justify-between items-center w-full col-span-3">
        <div class="flex justify-between items-center w-full col-span-3">
          <label
            for="memberNumber"
            class="search-date-label relative w-full md:w-[16vw]">
            <input
              @change="changeStartTime"
              :min="minDate"
              :max="maxDate"
              type="date"
              v-model="defaultTransactionRecord.sStartTime"
              class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
          </label>
          <span class="text-primary text-[12px] md:text-[14px] m-[12px]">
            {{ $t("MemberFunction.TradeRecord.To") }}</span
          >
          <label
            for="memberNumber"
            class="search-date-label relative w-full md:w-[16vw]">
            <input
              type="date"
              :min="minDate"
              :max="maxDate"
              v-model="defaultTransactionRecord.sEndTime"
              class="search-date-border text-primary text-[12px] w-full h-[100%] md:h-[3dvh] bg-transparent text-center bg-cover bg-center placeholder:text-primary" />
          </label>
          <button @click="getTradeRecordData" class="ml-[12px]">
            <img v-img-src="search_btn" alt="" class="h-auto md:h-[3vh]" />
          </button>
        </div>
      </div>
    </div>
    <ul
      class="flex justify-center md:justify-between items-center my-[1vh] leading-[10px]">
      <li
        v-for="time in timeLists"
        :key="time.title + 'tradeRecord'"
        class="flex justify-center md:justify-center items-center">
        <button
          class="group relative w-[100%] md:w-[6em] xl:w-[8em] text-[12px] md:text-[14px] mr-0"
          @click="time.fn"
          :class="{
            'text-primary': !time.isSelect,
            'text-secondary': time.isSelect,
          }">
          <img v-img-src="會員選擇無" alt="" v-show="!time.isSelect" />
          <img v-img-src="會員選擇" alt="" v-show="time.isSelect" />
          <p
            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
            {{ time.title }}
          </p>
        </button>
      </li>
    </ul>
    <div class="w-full h-[3px] bg-white/80 my-[2dvh] hidden md:block"></div>
    <div
      class="relative member-bg-border mb-[2rem] mt-0 md:mt-[1vh] py-[0px] px-[0] min-h-[60vh] md:min-h-[60vh]">
      <!-- main -->

      <div class="text-primary text-[12px] md:text-[14px] w-full px-0">
        <div class="overflow-auto">
          <table class="w-[100%] md:w-full table-auto">
            <thead>
              <tr>
                <th
                  class="w-[15%] h-[45px] text-start pl-[12px] bg-white/0 md:bg-white/20 rounded-tl-md">
                  {{ $t("MemberFunction.TradeRecord.Type") }}
                </th>
                <th
                  class="w-[25%] h-[45px] text-start pl-[12px] bg-white/0 md:bg-white/20">
                  {{ $t("MemberFunction.TradeRecord.List") }}
                </th>
                <th
                  class="w-[20%] h-[45px] pr-[6px] text-end bg-white/0 md:bg-white/20">
                  {{ $t("MemberFunction.TradeRecord.Change") }}
                </th>
                <th
                  class="w-[20%] h-[45px] pr-[6px] text-end bg-white/0 md:bg-white/20">
                  {{ $t("MemberFunction.TradeRecord.Notes") }}
                </th>
                <th
                  class="h-[45px] text-center bg-white/0 md:bg-white/20 rounded-tr-md">
                  {{ $t("MemberFunction.TradeRecord.Setting") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in memberService.CurrentTradeRecord"
                :key="'TradeRecordList' + item.nId"
                class="relative odd:bg-black/50 bg-white/10 last:after:h-[0px] after:absolute after:h-[1px] after:bg-white after:left-1/2 after:-translate-x-1/2">
                <td class="h-[45px] pl-[12px]">
                  {{
                    item.sType === "game"
                      ? $t("MemberFunction.TradeRecord.Game")
                      : item.sType === "money"
                      ? $t("MemberFunction.TradeRecord.Transaction")
                      : $t("MemberFunction.TradeRecord.Undefined")
                  }}
                </td>
                <td class="h-[45px] pl-[12px] text-start">
                  {{
                    item.sType === "game"
                      ? item.sShow
                        ? item.sShow.split(" ")[1]
                        : ""
                      : item.sShow
                      ? item.sShow.split(" ")[0]
                      : ""
                  }}
                </td>
                <td class="h-[45px] pr-[6px] text-end">
                  {{ store.getClearPotAndAddCommas(item.nDelta) }}
                </td>
                <td class="h-[45px] pr-[6px] text-end">
                  {{ item.sMoneyAbout }}
                </td>
                <td class="h-[45px] flex justify-around items-center">
                  <button
                    type="button"
                    class="btn-n-border"
                    @click="openTradeRecordAlert(item.nId)">
                    <p class="text-[12px] px-[1em] py-[.1em]">
                      {{ $t("MemberFunction.TradeRecord.Details") }}
                    </p>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="h-[45px] text-center pl-[6px] lg:pl-[2em]"></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          class="flex items-center justify-center absolute bottom-[10px] top-[unset] md:bottom-0 md:top-[90%] w-full left-1/2 -translate-x-1/2 text-[12px]">
          <button
            type="button"
            :disabled="
              memberService.TradeRecordPage === 1 ||
              memberService.TotalTradeRecordPage === 0
            "
            @click="goTradeRecordPage(1)"
            class="text-[10px] md:text-[16px] mr-[12px] text-primary"
            :class="{
              'text-primary/50': memberService.TradeRecordPage === 1,
            }"
            v-if="memberService.TotalTradeRecordPage > 1">
            {{ $t("MemberFunction.TradeRecord.FirstPage") }}
          </button>
          <button
            type="button"
            :disabled="
              memberService.TradeRecordPage === 1 ||
              memberService.TotalTradeRecordPage === 0
            "
            @click="goTradeRecordPage(memberService.TradeRecordPage - 1)"
            class="w-[10px]">
            <img v-img-src="頁面跳轉" alt="" />
          </button>
          <div
            class="text-primary bg-gray-600 border border-gray-50/80 rounded px-[12px] mx-[6px]">
            <p>
              <span class="text-[14px] font-bold">
                {{ memberService.TradeRecordPage }}
              </span>
              <span class="text-[10px] opacity-50">
                /
                {{
                  memberService.TotalTradeRecordPage
                    ? memberService.TotalTradeRecordPage
                    : 1
                }}
              </span>
            </p>
          </div>
          <!-- <ul class="flex text-primary">
              <li
                v-for="index in memberService.TotalTradeRecordPage"
                :key="`TradeRecordBtn${index}`"
                class="mx-[6px]"
              >
                <button
                  @click="goTradeRecordPage(index)"
                  :disabled="index === memberService.TradeRecordPage"
                  class="font-bold"
                  :class="
                    index === memberService.TradeRecordPage
                      ? 'text-primary'
                      : 'text-primary/50 hover:text-primary'
                  "
                >
                  {{ index }}
                </button>
              </li>
            </ul> -->
          <button
            type="button"
            class="rotate-180 w-[10px]"
            :disabled="
              memberService.TradeRecordPage ===
                memberService.TotalTradeRecordPage ||
              memberService.TotalTradeRecordPage === 0
            "
            @click="goTradeRecordPage(memberService.TradeRecordPage + 1)">
            <img v-img-src="頁面跳轉" alt="" />
          </button>
          <button
            type="button"
            :disabled="
              memberService.TradeRecordPage ===
                memberService.TotalTradeRecordPage ||
              memberService.TotalTradeRecordPage === 0
            "
            @click="goTradeRecordPage(memberService.TotalTradeRecordPage)"
            class="text-[10px] md:text-[16px] ml-[12px] text-primary"
            :class="{
              'text-primary/50':
                memberService.TradeRecordPage ===
                memberService.TotalTradeRecordPage,
            }"
            v-if="memberService.TotalTradeRecordPage > 1">
            {{ $t("MemberFunction.TradeRecord.LastPage") }}
          </button>
        </div>
      </div>

      <!-- 沒有資料 -->
      <div
        class="absolute left-1/2 top-3/1 -translate-x-1/2 flex flex-col items-center"
        v-if="memberService.CurrentTradeRecord.length === 0">
        <img v-img-src="no_file" alt="" class="w-[35px]" />
        <p class="text-primary text-[12px] text-center">
          {{ $t("MemberFunction.TradeRecord.NoData") }}
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
</style>
