<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useErrorAlert } from "@/stores/errorAlert";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";

import L_btn_p from "@image/memberFunction/01/L_btn_p.png";

import MemberAccountIcon_p from "@image/memberFunction/04_web/我的帳戶_p.png";
import MemberAccountIcon from "@image/memberFunction/04_web/我的帳戶.png";
import VIPPrivilegeIcon_p from "@image/memberFunction/04_web/VIP_p.png";
import VIPPrivilegeIcon from "@image/memberFunction/04_web/VIP.png";
import MemberListIcon_p from "@image/memberFunction/04_web/會員列表_p.png";
import MemberListIcon from "@image/memberFunction/04_web/會員列表.png";

import MemberMailIcon_p from "@image/memberFunction/04_web/會員信箱_p.png";
import MemberMailIcon from "@image/memberFunction/04_web/會員信箱.png";

import TakeOverCoinIcon_p from "@image/memberFunction/04_web/我的錢包_p.png";
import TakeOverCoinIcon from "@image/memberFunction/04_web/我的錢包.png";

import BetRecordIcon_p from "@image/memberFunction/04_web/下注紀錄_p.png";
import BetRecordIcon from "@image/memberFunction/04_web/下注紀錄.png";

import TradeRecordIcon_p from "@image/memberFunction/04_web/交易紀錄_p.png";
import TradeRecordIcon from "@image/memberFunction/04_web/交易紀錄.png";

import PromoteLinkIcon_p from "@image/memberFunction/04_web/推廣_p.png";
import PromoteLinkIcon from "@image/memberFunction/04_web/推廣.png";

import RankingListIcon_p from "@image/memberFunction/04_web/排行_p.png";
import RankingListIcon from "@image/memberFunction/04_web/排行.png";

import NewMessageIcon_p from "@image/memberFunction/04_web/系統公告_p.png";
import NewMessageIcon from "@image/memberFunction/04_web/系統公告.png";

import ShareMakeFortunePageIcon_p from "@image/memberFunction/04_web/分享發財_p.png";
import ShareMakeFortunePageIcon from "@image/memberFunction/04_web/分享發財.png";

const router = useRouter();
const { t } = useI18n();

const login = useLogin();
const store = useCounterStore();
const errorAlert = useErrorAlert();
const memberService = useMemberService();
const { memberData, isCashMember } = login;
const { MailCount } = storeToRefs(memberService);
interface PathList {
  title: string;
  pathName: string;
}
// 側邊選單列表
const pathList1 = computed(() => {
  if (isCashMember) {
    if (memberData.nRank === "0") {
      return [
        {
          title: t("Header.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("Header.VIPPrivilege"),
          pathName: "VIPPrivilege",
        },
        {
          title: t("Header.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    } else {
      return [
        {
          title: t("Header.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("Header.VIPPrivilege"),
          pathName: "VIPPrivilege",
        },
        {
          title: t("Header.MemberList"),
          pathName: "MemberList",
        },
        {
          title: t("Header.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    }
  } else {
    if (memberData.nRank === "0") {
      return [
        {
          title: t("Header.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("Header.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    } else {
      return [
        {
          title: t("Header.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("Header.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    }
  }
});
const pathList2 = ref<PathList[]>([
  {
    title: t("Header.TakeOverCoin"),
    pathName: "TakeOverCoin",
  },
  {
    title: t("Header.BetRecord"),
    pathName: "BetRecord",
  },
]);
const pathList3 = computed(() => {
  if (isCashMember) {
    if (memberData.nRank === "0") {
      return [
        {
          title: t("Header.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("Header.NewMessage"),
          pathName: "NewMessage",
        },
        {
          title: t("Header.ShareMakeFortunePage"),
          pathName: "ShareMakeFortunePage",
        },
      ];
    } else {
      return [
        {
          title: t("Header.PromoteLink"),
          pathName: "PromoteLink",
        },
        {
          title: t("Header.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("Header.NewMessage"),
          pathName: "NewMessage",
        },
      ];
    }
  } else {
    if (memberData.nRank === "0") {
      return [
        {
          title: t("Header.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("Header.NewMessage"),
          pathName: "NewMessage",
        },
      ];
    } else {
      return [
        {
          title: t("Header.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("Header.NewMessage"),
          pathName: "NewMessage",
        },
      ];
    }
  }
});
// 進入選單
function toPath(pathName: string) {
  // if (pathName === "ShareMakeFortunePage") {
  //   return errorAlert.AddErrorAlert("敬請期待");
  // }
  router.push({ name: pathName });
}
// 取得選單icon
function getIconOn(path: string) {
  let image;
  switch (path) {
    case "MemberAccount":
      image = MemberAccountIcon_p;
      break;
    case "VIPPrivilege":
      image = VIPPrivilegeIcon_p;
      break;
    case "MemberMail":
      image = MemberMailIcon_p;
      break;
    case "TakeOverCoin":
      image = TakeOverCoinIcon_p;
      break;
    case "MemberList":
      image = MemberListIcon_p;
      break;
    case "BetRecord":
      image = BetRecordIcon_p;
      break;
    case "TradeRecord":
      image = TradeRecordIcon_p;
      break;
    case "PromoteLink":
      image = PromoteLinkIcon_p;
      break;
    case "RankingList":
      image = RankingListIcon_p;
      break;
    case "NewMessage":
      image = NewMessageIcon_p;
      break;
    case "ShareMakeFortunePage":
      image = ShareMakeFortunePageIcon_p;
      break;
    default:
      image = MemberAccountIcon_p;
      break;
  }
  return image;
}
function getIconOff(path: string) {
  let image;
  switch (path) {
    case "MemberAccount":
      image = MemberAccountIcon;
      break;
    case "VIPPrivilege":
      image = VIPPrivilegeIcon;
      break;
    case "MemberMail":
      image = MemberMailIcon;
      break;
    case "MemberList":
      image = MemberListIcon;
      break;
    case "TakeOverCoin":
      image = TakeOverCoinIcon;
      break;
    case "BetRecord":
      image = BetRecordIcon;
      break;
    case "TradeRecord":
      image = TradeRecordIcon;
      break;
    case "PromoteLink":
      image = PromoteLinkIcon;
      break;
    case "RankingList":
      image = RankingListIcon;
      break;
    case "NewMessage":
      image = NewMessageIcon;
      break;
    case "ShareMakeFortunePage":
      image = ShareMakeFortunePageIcon;
      break;
    default:
      image = MemberAccountIcon;
      break;
  }
  return image;
}

// 登出
function openLogoutAlert() {
  store.toggleAlertBg();
  store.openLogoutAlert();
}
</script>
<template>
  <div class="hidden md:block">
    <ul class="w-[200px] bg-black/50 backdrop-blur-sm pb-[2dvh] rounded-xl">
      <li v-for="type in pathList1" :key="type.title + 'PathNavbar'">
        <button
          type="button"
          class="group relative w-full py-[1.5dvh]"
          @click="toPath(type.pathName)">
          <div
            class="hidden group-hover:block h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <img v-img-src="L_btn_p" alt="" class="w-full h-[120%]" />
          </div>
          <div
            class="relative z-10 w-full h-full px-[1.5vw] flex justify-start items-center">
            <div
              class="absolute ml-[15px] mb-[15px] bg-red-600 w-[12px] h-[12px] border border-white rounded-full"
              v-if="type.pathName === 'MemberMail' && MailCount !== '0'"></div>
            <img
              v-img-src="getIconOn(type.pathName)"
              alt=""
              class="mr-[2vw] w-[20px] hidden group-hover:block" />
            <img
              v-img-src="getIconOff(type.pathName)"
              alt=""
              class="mr-[2vw] w-[20px] block group-hover:hidden" />
            <p
              class="font-bold text-[16px] text-primary group-hover:text-secondary mr-[1dvh]">
              {{ type.title }}
            </p>
          </div>
        </button>
      </li>
      <div
        class="h-[3px] w-[80%] my-[1dvh] mx-auto rounded-md bg-yellow-200"></div>
      <li v-for="type in pathList2" :key="type.title + 'PathNavbar'">
        <button
          type="button"
          class="group relative w-full py-[1.5dvh]"
          @click="toPath(type.pathName)">
          <div
            class="hidden group-hover:block h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <img v-img-src="L_btn_p" alt="" class="w-full h-[120%]" />
          </div>
          <div
            class="relative z-10 w-full h-full px-[1.5vw] flex justify-start items-center">
            <img
              v-img-src="getIconOn(type.pathName)"
              alt=""
              class="mr-[2vw] w-[20px] hidden group-hover:block" />
            <img
              v-img-src="getIconOff(type.pathName)"
              alt=""
              class="mr-[2vw] w-[20px] block group-hover:hidden" />
            <p
              class="font-bold text-[16px] text-primary group-hover:text-secondary mr-[1dvh]">
              {{ type.title }}
            </p>
          </div>
        </button>
      </li>
      <div
        class="h-[3px] w-[80%] my-[1dvh] mx-auto rounded-md bg-yellow-200"></div>
      <li v-for="type in pathList3" :key="type.title + 'PathNavbar'">
        <button
          type="button"
          class="group relative w-full py-[1.5dvh]"
          @click="toPath(type.pathName)">
          <div
            class="hidden group-hover:block h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <img v-img-src="L_btn_p" alt="" class="w-full h-[120%]" />
          </div>
          <div
            class="relative z-10 w-full h-full px-[1.5vw] flex justify-start items-center">
            <img
              v-img-src="getIconOn(type.pathName)"
              alt=""
              class="mr-[2vw] w-[20px] hidden group-hover:block" />
            <img
              v-img-src="getIconOff(type.pathName)"
              alt=""
              class="mr-[2vw] w-[20px] block group-hover:hidden" />
            <p
              class="font-bold text-[16px] text-primary group-hover:text-secondary mr-[1vh]">
              {{ type.title }}
            </p>
          </div>
        </button>
      </li>
      <li class="my-[2dvh]">
        <button
          type="button"
          class="web-logout-bg px-[2vw] py-[1dvh]"
          @click="openLogoutAlert">
          <p class="text-main text-center">{{ $t("Header.Logout") }}</p>
        </button>
      </li>
    </ul>
  </div>
</template>
<style>
.web-logout-bg {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/02_web/登出_btn.png") 1;
  -webkit-border-image: url("@image/memberFunction/02_web/登出_btn.png") 1;
  -o-border-image: url("@image/memberFunction/02_web/登出_btn.png") 1;
  border-image: url("@image/memberFunction/02_web/登出_btn.png") 1 fill;
}
</style>
