<script setup lang="ts">
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import LoginPage from "@/components/login/loginPage.vue";
import RegisterPage from "@/components/login/registerPage.vue";
import BgSoundControl from "@/components/bgSoundControl.vue";
import { useContentInfo } from "@/stores/contentInfo";
import { useLogin } from "@/stores/login";

import LOGO from "@image/memberFunction/07/LOGO.gif";
import login_up_frame from "@image/memberFunction/07/login_up_frame.png";
import login_up_btn from "@image/memberFunction/07/login_up_btn.png";

const login = useLogin();
const router = useRouter();
const counterInfo = useContentInfo();
const { PromoCode, Account, Share } = storeToRefs(login);
const { WebCreditType } = storeToRefs(counterInfo);
// 0: 登入, 1: 註冊
// const Mode = ref<number>(0);
function selectMode(mode: number) {
  login.selectMode(mode);
}
</script>

<template>
  <div class="relative flex flex-col min-h-[100dvh] items-center justify-start z-10">
    <div
      class="absolute brightness-[.6] w-full h-full bg-cover md:bg-[url('@image/memberFunction/01_web/login_page.jpg')] bg-[url('@image/memberFunction/01/bg_01.png')]">
    </div>
    <div
      class="z-10 md:w-[315.5px] w-[100%] md:p-0 p-[36px] static md:absolute top-0 md:top-1/2 -translate-y-0 md:-translate-y-1/2">
      <button class="mt-0 mx-[3dvh]">
        <img v-img-src="LOGO" alt="" class="" />
      </button>
      <div class="relative mt-[12px] mb-[24px] leading-4">
        <img v-img-src="login_up_frame" alt="" class="w-full h-[50px]" />
        <button class="absolute top-1/2 -translate-y-1/2 left-[4px]" @click="selectMode(0)">
          <div class="relative">
            <p class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold drop-shadow-md"
              :class="login.PageMode === 0 ? 'text-[#342814]' : 'text-primary'">
              {{ $t("LoginView.Login") }}
            </p>
            <img v-img-src="login_up_btn" alt="" :class="login.PageMode === 0 ? 'opacity-1' : 'opacity-0'"
              class="h-[40px]" />
          </div>
        </button>
        <button class="absolute top-1/2 -translate-y-1/2 right-[4px]" :disabled="WebCreditType === 1"
          @click="selectMode(1)">
          <div class="relative">
            <p class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold drop-shadow-md"
              :class="login.PageMode === 1 ? 'text-[#342814]' : 'text-primary'"
              :style="{ opacity: WebCreditType === 1 ? '0' : '1' }">
              {{ $t("LoginView.Register") }}
            </p>
            <img v-img-src="login_up_btn" alt="" :class="login.PageMode === 1 ? 'opacity-1' : 'opacity-0'"
              class="h-[40px]" />
          </div>
        </button>
      </div>
      <LoginPage v-if="login.PageMode === 0" />
      <RegisterPage :promoCode="PromoCode" :share="Share" :account="Account" @selectMode="selectMode"
        v-else-if="login.PageMode === 1" />
    </div>

    <div class="absolute top-[6px] md:top-[1vh] right-[6px] md:right-[1vw]">
      <BgSoundControl />
    </div>
  </div>
</template>

<style></style>
