<script setup lang="ts">
import { storeToRefs } from "pinia";

import rank_list from "@image/memberFunction/14/rank_list.png";
import my_up from "@image/memberFunction/14/my_up.png";
import no4_30 from "@image/memberFunction/14/no4-30.png";
import coin_icon from "@image/memberFunction/14/coin_icon.png";
import time_icon from "@image/memberFunction/14/time_icon.png";
import bg_01 from "@image/memberFunction/14/bg-01.png";
import 三角_02 from "@image/memberFunction/14/三角-02.png";

// import VIPIconImage1 from "@image/memberFunction/16/VIP1.png";
// import VIPIconImage2 from "@image/memberFunction/16/VIP2.png";
// import VIPIconImage3 from "@image/memberFunction/16/VIP3.png";
// import VIPIconImage4 from "@image/memberFunction/16/VIP4.png";
// import VIPIconImage5 from "@image/memberFunction/16/VIP5.png";
// import VIPIconImage6 from "@image/memberFunction/16/VIP6.png";
// import VIPIconImage7 from "@image/memberFunction/16/VIP7.png";
// import VIPIconImage8 from "@image/memberFunction/16/VIP8.png";
// import VIPIconImage9 from "@image/memberFunction/16/VIP9.png";

import VIPFlagImage1 from "@image/memberFunction/common/VIP1.png";
import VIPFlagImage2 from "@image/memberFunction/common/VIP2.png";
import VIPFlagImage3 from "@image/memberFunction/common/VIP3.png";
import VIPFlagImage4 from "@image/memberFunction/common/VIP4.png";
import VIPFlagImage5 from "@image/memberFunction/common/VIP5.png";
import VIPFlagImage6 from "@image/memberFunction/common/VIP6.png";
import VIPFlagImage7 from "@image/memberFunction/common/VIP7.png";
import VIPFlagImage8 from "@image/memberFunction/common/VIP8.png";
import VIPFlagImage9 from "@image/memberFunction/common/VIP9.png";

import No1Image from "@image/memberFunction/14/no1.png";
import No2Image from "@image/memberFunction/14/no2.png";
import No3Image from "@image/memberFunction/14/no3.png";

import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useLogin } from "@/stores/login";

import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
const store = useCounterStore();
const memberService = useMemberService();
const login = useLogin();
const {
  RankingMemberList,
  RankingMemberUpdateTime,
  RankingMemberSelf,
  nDay,
  nDataType,
} = storeToRefs(memberService);
const { memberData } = storeToRefs(login);
const getVIPIconImage = (level: string) => {
  let image;
  switch (level) {
    case "0":
    case "1":
      // image = VIPIconImage1;
      image = VIPFlagImage1;
      break;
    case "2":
      // image = VIPIconImage2;
      image = VIPFlagImage2;
      break;
    case "3":
      // image = VIPIconImage3;
      image = VIPFlagImage3;
      break;
    case "4":
      // image = VIPIconImage4;
      image = VIPFlagImage4;
      break;
    case "5":
      // image = VIPIconImage5;
      image = VIPFlagImage5;
      break;
    case "6":
      // image = VIPIconImage6;
      image = VIPFlagImage6;
      break;
    case "7":
      // image = VIPIconImage7;
      image = VIPFlagImage7;
      break;
    case "8":
      // image = VIPIconImage8;
      image = VIPFlagImage8;
      break;
    case "9":
      // image = VIPIconImage9;
      image = VIPFlagImage9;
      break;
    default:
      image = "";
      break;
  }
  return image;
};

function openContentRankingListDateAlert() {
  store.toggleAlertBg();
  store.openContentRankingListDateAlert();
}

function openContentRankingListTypeAlert() {
  store.toggleAlertBg();
  store.openContentRankingListTypeAlert();
}

function getNoImage(No: number) {
  let image;
  switch (No) {
    case 0:
      image = No1Image;
      break;
    case 1:
      image = No2Image;
      break;
    case 2:
      image = No3Image;
      break;
    default:
      image = "";
      break;
  }
  return image;
}
function replaceMiddleWithAsterisks(text: string) {
  if (text.length < 3) {
    return text; // 如果文本長度小於3，無法替換中間三個字符，直接返回原始文本
  }

  var start = Math.floor((text.length - 3) / 2);
  var end = start + 3;

  var replacedText = text.substring(0, start) + "***" + text.substring(end);
  return replacedText;
}
</script>

<template>
  <div class="w-full md:max-w-[1366px] mx-auto">
    <div
      class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[10dvh] pt-0 md:pt-[3rem]">
      <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
      <div class="col-span-9 relative web-gold-border rounded-[24px]">
        <div class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10">
          <p
            class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
            {{ $t("Context.RankingList.Ranking") }}
          </p>
          <div>
            <div
              class="flex flex-col md:flex-row justify-center items-center mt-[2dvh]">
              <div class="w-[260px] md:w-[20vw] mx-auto">
                <img v-img-src="rank_list" alt="" />
              </div>

              <!-- 自己的排行 -->
              <div class="flex flex-col">
                <div class="mx-[24px] relative hidden md:block">
                  <div
                    class="absolute -translate-y-1/2 left-1/2 -translate-x-1/2 z-20">
                    <div class="relative">
                      <img
                        v-img-src="my_up"
                        alt=""
                        class="top-0 w-[15vw] relative z-10" />
                      <p
                        class="text-[12px] text-primary whitespace-nowrap absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 px-1">
                        {{ $t("Context.RankingList.MyRanking") }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-2 gap-y-[3dvh] gap-x-[2vw] my-bg-web-border px-[3vw] py-[3dvh] rounded-[24px]"
                    style="font-family: impact">
                    <div
                      class="relative flex justify-center items-center"
                      style="font-family: impact"
                      v-if="
                        RankingMemberSelf.index >= 3 ||
                        isNaN(RankingMemberSelf.index)
                      ">
                      <img v-img-src="no4_30" alt="" class="absolute h-full" />
                      <p class="text-primary text-[26px] relative z-10">
                        {{
                          isNaN(RankingMemberSelf.index)
                            ? "-"
                            : RankingMemberSelf.index + 1
                        }}
                      </p>
                    </div>
                    <img
                      v-img-src="getNoImage(RankingMemberSelf.index)"
                      alt=""
                      class="rounded-t-[12px] w-[3vw] mx-auto"
                      v-else />

                    <p
                      class="flex justify-center items-center text-primary text-[20px] text-yellow-400">
                      <img
                        v-img-src="coin_icon"
                        alt=""
                        class="w-[14px] mr-[2px]" />
                      {{
                        store.addCommas(
                          store.getClearPot(RankingMemberSelf.nMoney, 1)
                        )
                      }}
                    </p>

                    <div
                      class="flex justify-end items-center mx-auto text-[18px]">
                      <p class="text-primary">{{ memberData.sAccount }}</p>
                    </div>
                    <div class="relative">
                      <img
                        v-if="memberData.aVipData"
                        v-img-src="getVIPIconImage(memberData.aVipData.nVipLv)"
                        alt=""
                        class="w-full absolute top-[-6px]" />
                    </div>
                  </div>
                </div>

                <div
                  class="flex justify-center items-center text-primary mt-[6px]">
                  <img v-img-src="time_icon" alt="" class="w-[12px] mr-1" />
                  <p class="text-[14px]">
                    {{ $t("Context.RankingList.UpdateTime") }}
                    {{ RankingMemberUpdateTime }}
                  </p>
                </div>
              </div>
              <!-- 自己的排行 -->
            </div>
            <div
              class="m-[24px] relative block md:hidden"
              style="font-family: impact">
              <div
                class="absolute -translate-y-1/2 left-1/2 -translate-x-1/2 z-20">
                <div class="relative">
                  <img
                    v-img-src="my_up"
                    alt=""
                    class="top-0 w-[130px] relative z-10" />
                  <p
                    class="text-[12px] text-primary whitespace-nowrap absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 px-1">
                    {{ $t("Context.RankingList.MyRanking") }}
                  </p>
                </div>
              </div>

              <div class="my-bg-border text-primary text-[12px] w-full">
                <tbody class="w-full">
                  <tr>
                    <td class="py-[12px] px-[12px] w-[15%]">
                      <div
                        class="relative flex justify-center items-center w-[30px]"
                        v-if="
                          RankingMemberSelf.index >= 3 ||
                          isNaN(RankingMemberSelf.index)
                        ">
                        <img
                          v-img-src="no4_30"
                          alt=""
                          class="absolute h-[30px]" />
                        <p class="text-primary text-[16px] relative z-10">
                          {{
                            isNaN(RankingMemberSelf.index)
                              ? "-"
                              : RankingMemberSelf.index + 1
                          }}
                        </p>
                      </div>
                      <img
                        v-img-src="getNoImage(RankingMemberSelf.index)"
                        alt=""
                        class="rounded-t-[12px] mx-auto h-[30px]"
                        v-else />
                    </td>
                    <td class="">
                      <p>{{ memberData.sAccount }}</p>
                    </td>
                    <td class="w-[20%] text-start">
                      <div class="relative px-[8px] top-[-2px] left-[-22px]">
                        <img
                          v-img-src="
                            getVIPIconImage(memberData.aVipData.nVipLv)
                          "
                          alt=""
                          class="w-full" />
                      </div>
                    </td>
                    <td class="pr-[12px]">
                      <div class="flex justify-end items-center text-[14px]">
                        <img
                          v-img-src="coin_icon"
                          alt=""
                          class="w-[16px] mr-[2px]" />
                        <p class="text-yellow-400">
                          {{
                            store.addCommas(
                              store.getClearPot(RankingMemberSelf.nMoney, 1)
                            )
                          }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </div>
            </div>

            <!-- 全部排行 -->
            <div class="mb-[10dvh] mt-[1em]">
              <div class="relative h-full">
                <img
                  v-img-src="bg_01"
                  alt=""
                  class="rounded-t-[12px] absolute top-1/2 -translate-y-1/2 h-full w-full" />
                <div
                  class="relative z-10 flex justify-between w-full text-[14px] md:text-[16px] text-primary px-[12px] py-[13px]">
                  <div class="flex items-center">
                    <p class="mr-[6px] truncate">
                      {{ $t("Context.RankingList.Date") }}
                    </p>
                    <button
                      type="button"
                      class="flex justify-between items-center btn-y-border px-[1em] py-[2px]"
                      @click="openContentRankingListDateAlert">
                      <span
                        class="mr-[12px] text-secondary py-[2px] leading-3"
                        >{{
                          nDay === "1"
                            ? $t("MemberFunction.BetRecord.Today")
                            : nDay === "2"
                            ? $t("MemberFunction.BetRecord.Yesterday")
                            : nDay === "3"
                            ? $t("MemberFunction.BetRecord.ThisWeek")
                            : nDay === "4"
                            ? $t("MemberFunction.BetRecord.LastWeek")
                            : nDay === "5"
                            ? $t("MemberFunction.BetRecord.ThisMonth")
                            : ""
                        }}</span
                      >
                      <img v-img-src="三角_02" alt="" class="w-[12px]" />
                    </button>
                  </div>
                  <div class="flex items-center">
                    <p class="mr-[6px] truncate">
                      {{ $t("Context.RankingList.Type") }}
                    </p>
                    <button
                      type="button"
                      class="flex justify-between items-center btn-y-border px-[.8em] py-[2px]">
                      <span
                        class="mr-[6px] text-secondary leading-3 py-[2px]"
                        >{{
                          nDataType === "1"
                            ? $t("Context.RankingList.WageringRank")
                            : $t("Context.RankingList.DepositRank")
                        }}</span
                      >
                      <!-- <img v-img-src="三角_02" alt="" class="w-[12px]" /> -->
                    </button>
                  </div>
                </div>
              </div>
              <table
                class="bg-list-even w-full text-white text-[12px] md:text-[16px]">
                <thead class="">
                  <tr>
                    <th class="pt-[1vh] pb-[1vh] text-start w-[15%] pl-[1vw]">
                      {{ $t("Context.RankingList.Rank") }}
                    </th>
                    <th class="text-start">
                      {{ $t("Context.RankingList.Account") }}
                    </th>
                    <th class="w-[18%] md:w-[12%] text-center">
                      {{ $t("Context.RankingList.VIP") }}
                    </th>
                    <th class="pr-[1vw] text-end">
                      {{
                        nDataType === "1"
                          ? $t("Context.RankingList.WageringAmount")
                          : $t("Context.RankingList.DepositAmount")
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(value, index) in RankingMemberList.slice(0, 30)"
                    :key="'RankingList' + value.sAccount"
                    class="bg-list-even odd:bg-list-odd">
                    <td
                      class="flex justify-start items-center py-[8px] pl-[1vw]">
                      <div
                        class="relative flex justify-center items-center w-[30px]"
                        v-if="index >= 3">
                        <img
                          v-img-src="no4_30"
                          alt=""
                          class="absolute w-full" />
                        <p
                          class="text-primary text-center text-[16px] left-[-1px] relative z-10"
                          style="font-family: impact">
                          {{ index + 1 }}
                        </p>
                      </div>
                      <img
                        v-img-src="getNoImage(index)"
                        :key="index"
                        alt=""
                        class="rounded-t-[12px] w-[26px]"
                        v-else />
                    </td>
                    <td class="py-[8px]">
                      <p style="font-family: impact">
                        {{ replaceMiddleWithAsterisks(value.sAccount) }}
                      </p>
                    </td>
                    <td class="py-[8px]">
                      <div class="relative px-[8px] md:px-[16px]">
                        <img
                          v-img-src="getVIPIconImage(value.nVipLv)"
                          alt=""
                          class="w-full top-[-3px]" />
                      </div>
                    </td>
                    <td class="py-[8px] pr-[1vw]">
                      <div class="flex justify-end items-center text-[14px]">
                        <img
                          v-img-src="coin_icon"
                          alt=""
                          class="w-[16px] mr-[2px]" />
                        <p class="text-yellow-400" style="font-family: impact">
                          {{
                            nDataType === "2"
                              ? store.addCommas(
                                  store.getClearPot(value.nMoney, 1)
                                )
                              : store.addCommas(
                                  store.getClearPot(value.nMoney, 1)
                                )
                          }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.my-bg-border {
  border-style: solid;
  border-width: 2px;
  -moz-border-image: url("@image/memberFunction/14/my_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/14/my_bg.png") 1;
  -o-border-image: url("@image/memberFunction/14/my_bg.png") 1;
  border-image: url("@image/memberFunction/14/my_bg.png") 5 fill;
}
.my-bg-web-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/14/web_my_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/14/web_my_bg.png") 1;
  -o-border-image: url("@image/memberFunction/14/web_my_bg.png") 1;
  border-image: url("@image/memberFunction/14/web_my_bg.png") 1 fill;
}
</style>
